import Selector from 'mdui.jq/es/types/Selector';
import mdui from '../../mdui';
import 'mdui.jq/es/methods/on';
import { $document } from '../../utils/dom';
import { currentInst, OPTIONS, Dialog } from './class';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * Dialog 缁勪欢
     *
     * 璇烽€氳繃 `new mdui.Dialog()` 璋冪敤
     */
    Dialog: {
      /**
       * 瀹炰緥鍖 Dialog 缁勪欢
       * @param selector CSS 閫夋嫨鍣ㄣ€佹垨 DOM 鍏冪礌銆佹垨 JQ 瀵硅薄
       * @param options 閰嶇疆鍙傛暟
       */
      new (
        selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
        options?: OPTIONS,
      ): Dialog;
    };
  }
}

// esc 鎸変笅鏃跺叧闂璇濇
$document.on('keydown', (event: Event) => {
  if (
    currentInst &&
    currentInst.options.closeOnEsc &&
    currentInst.state === 'opened' &&
    (event as KeyboardEvent).keyCode === 27
  ) {
    currentInst.close();
  }
});

mdui.Dialog = Dialog;
