import $ from 'mdui.jq/es/$';
import 'mdui.jq/es/methods/data';
import 'mdui.jq/es/methods/removeClass';
import 'mdui.jq/es/methods/width';
import { $body } from '../../utils/dom';

declare module 'mdui.jq/es/interfaces/JQStatic' {
  interface JQStatic {
    /**
     * 瑙ｉ櫎椤甸潰閿佸畾
     *
     * 濡傛灉璋冪敤浜嗗娆 $.lockScreen() 鏉ユ樉绀洪伄缃╁眰锛屽垯涔熼渶瑕佽皟鐢ㄧ浉鍚屾鏁扮殑 $.unlockScreen() 鎵嶈兘闅愯棌閬僵灞傘€傚彲浠ラ€氳繃浼犲叆鍙傛暟 true 鏉ュ己鍒堕殣钘忛伄缃╁眰銆俓n     * @param force 鏄惁寮哄埗瑙ｉ櫎閿佸畾
     * @example
```js
$.unlockScreen();
```
     * @example
```js
$.unlockScreen(true);
```
     */
    unlockScreen(force?: boolean): void;
  }
}

$.unlockScreen = function (force = false): void {
  let level = force ? 1 : $body.data('_lockscreen_level');

  if (level > 1) {
    $body.data('_lockscreen_level', --level);
    return;
  }

  $body.data('_lockscreen_level', 0).removeClass('mdui-locked').width('');
};
