import $ from 'mdui.jq/es/$';
import 'mdui.jq/es/methods/each';
import 'mdui.jq/es/methods/hasClass';
import 'mdui.jq/es/methods/html';
import Selector from 'mdui.jq/es/types/Selector';
import { isUndefined } from 'mdui.jq/es/utils';
import mdui from '../../mdui';
import '../../global/mutation';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * 濡傛灉闇€瑕佷慨鏀瑰凡鏈夌殑鍦嗗舰杩涘害鏉＄粍浠讹紝闇€瑕佽皟鐢ㄨ鏂规硶鏉ラ噸鏂板垵濮嬪寲缁勪欢銆俓n     *
     * 鑻ヤ紶鍏ヤ簡鍙傛暟锛屽垯鍙噸鏂板垵濮嬪寲璇ュ弬鏁板搴旂殑鍦嗗舰杩涘害鏉°€傝嫢娌℃湁浼犲叆鍙傛暟锛屽垯閲嶆柊鍒濆鍖栨墍鏈夊渾褰㈣繘搴︽潯銆俓n     * @param selector CSS 閫夋嫨鍣ㄣ€佹垨 DOM 鍏冪礌銆佹垨 DOM 鍏冪礌缁勬垚鐨勬暟缁勩€佹垨 JQ 瀵硅薄
     */
    updateSpinners(
      selector?: Selector | HTMLElement | ArrayLike<HTMLElement>,
    ): void;
  }
}

/**
 * layer 鐨 HTML 缁撴瀯
 * @param index
 */
function layerHTML(index: number | false = false): string {
  return (
    `<div class="mdui-spinner-layer ${
      index ? `mdui-spinner-layer-${index}` : ''
    }">` +
    '<div class="mdui-spinner-circle-clipper mdui-spinner-left">' +
    '<div class="mdui-spinner-circle"></div>' +
    '</div>' +
    '<div class="mdui-spinner-gap-patch">' +
    '<div class="mdui-spinner-circle"></div>' +
    '</div>' +
    '<div class="mdui-spinner-circle-clipper mdui-spinner-right">' +
    '<div class="mdui-spinner-circle"></div>' +
    '</div>' +
    '</div>'
  );
}

/**
 * 濉厖 HTML
 * @param spinner
 */
function fillHTML(spinner: HTMLElement): void {
  const $spinner = $(spinner);

  const layer = $spinner.hasClass('mdui-spinner-colorful')
    ? layerHTML(1) + layerHTML(2) + layerHTML(3) + layerHTML(4)
    : layerHTML();

  $spinner.html(layer);
}

$(() => {
  // 椤甸潰鍔犺浇瀹屽悗鑷姩濉厖 HTML 缁撴瀯
  mdui.mutation('.mdui-spinner', function () {
    fillHTML(this);
  });
});

mdui.updateSpinners = function (
  selector?: Selector | HTMLElement | ArrayLike<HTMLElement>,
): void {
  const $elements = isUndefined(selector) ? $('.mdui-spinner') : $(selector);

  $elements.each(function () {
    fillHTML(this);
  });
};
