import $ from 'mdui.jq/es/$';
import contains from 'mdui.jq/es/functions/contains';
import extend from 'mdui.jq/es/functions/extend';
import { JQ } from 'mdui.jq/es/JQ';
import 'mdui.jq/es/methods/addClass';
import 'mdui.jq/es/methods/attr';
import 'mdui.jq/es/methods/children';
import 'mdui.jq/es/methods/css';
import 'mdui.jq/es/methods/data';
import 'mdui.jq/es/methods/each';
import 'mdui.jq/es/methods/find';
import 'mdui.jq/es/methods/first';
import 'mdui.jq/es/methods/hasClass';
import 'mdui.jq/es/methods/height';
import 'mdui.jq/es/methods/is';
import 'mdui.jq/es/methods/on';
import 'mdui.jq/es/methods/parent';
import 'mdui.jq/es/methods/parents';
import 'mdui.jq/es/methods/removeClass';
import 'mdui.jq/es/methods/width';
import Selector from 'mdui.jq/es/types/Selector';
import mdui from '../../mdui';
import '../../jq_extends/methods/transformOrigin';
import '../../jq_extends/methods/transitionEnd';
import '../../jq_extends/static/throttle';
import { componentEvent } from '../../utils/componentEvent';
import { $document, $window } from '../../utils/dom';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * Menu 缁勪欢
     *
     * 璇烽€氳繃 `new mdui.Menu()` 璋冪敤
     */
    Menu: {
      /**
       * 瀹炰緥鍖 Menu 缁勪欢
       * @param anchorSelector 瑙﹀彂鑿滃崟鐨勫厓绱犵殑 CSS 閫夋嫨鍣ㄣ€佹垨 DOM 鍏冪礌銆佹垨 JQ 瀵硅薄
       * @param menuSelector 鑿滃崟鐨 CSS 閫夋嫨鍣ㄣ€佹垨 DOM 鍏冪礌銆佹垨 JQ 瀵硅薄
       * @param options 閰嶇疆鍙傛暟
       */
      new (
        anchorSelector: Selector | HTMLElement | ArrayLike<HTMLElement>,
        menuSelector: Selector | HTMLElement | ArrayLike<HTMLElement>,
        options?: OPTIONS,
      ): Menu;
    };
  }
}

type OPTIONS = {
  /**
   * 鑿滃崟鐩稿浜庤Е鍙戝畠鐨勫厓绱犵殑浣嶇疆锛岄粯璁や负 `auto`銆俓n   * 鍙栧€艰寖鍥村寘鎷細
   *   `top`: 鑿滃崟鍦ㄨЕ鍙戝畠鐨勫厓绱犵殑涓婃柟
   *   `bottom`: 鑿滃崟鍦ㄨЕ鍙戝畠鐨勫厓绱犵殑涓嬫柟
   *   `center`: 鑿滃崟鍦ㄧ獥鍙ｄ腑鍨傜洿灞呬腑
   *   `auto`: 鑷姩鍒ゆ柇浣嶇疆銆備紭鍏堢骇涓猴細`bottom` > `top` > `center`
   */
  position?: 'auto' | 'top' | 'bottom' | 'center';

  /**
   * 鑿滃崟涓庤Е鍙戝畠鐨勫厓绱犵殑瀵瑰叾鏂瑰紡锛岄粯璁や负 `auto`銆俓n   * 鍙栧€艰寖鍥村寘鎷細
   *   `left`: 鑿滃崟涓庤Е鍙戝畠鐨勫厓绱犲乏瀵归綈
   *   `right`: 鑿滃崟涓庤Е鍙戝畠鐨勫厓绱犲彸瀵归綈
   *   `center`: 鑿滃崟鍦ㄧ獥鍙ｄ腑姘村钩灞呬腑
   *   `auto`: 鑷姩鍒ゆ柇浣嶇疆锛氫紭鍏堢骇涓猴細`left` > `right` > `center`
   */
  align?: 'auto' | 'left' | 'right' | 'center';

  /**
   * 鑿滃崟涓庣獥鍙ｈ竟妗嗚嚦灏戜繚鎸佸灏戦棿璺濓紝鍗曚綅涓 px锛岄粯璁や负 `16`
   */
  gutter?: number;

  /**
   * 鑿滃崟鐨勫畾浣嶆柟寮忥紝榛樿涓 `false`銆俓n   * 涓 `true` 鏃讹紝鑿滃崟浣跨敤 fixed 瀹氫綅銆傚湪椤甸潰婊氬姩鏃讹紝鑿滃崟灏嗕繚鎸佸湪绐楀彛鍥哄畾浣嶇疆锛屼笉闅忔粴鍔ㄦ潯婊氬姩銆俓n   * 涓 `false` 鏃讹紝鑿滃崟浣跨敤 absolute 瀹氫綅銆傚湪椤甸潰婊氬姩鏃讹紝鑿滃崟灏嗛殢鐫€椤甸潰涓€璧锋粴鍔ㄣ€俓n   */
  fixed?: boolean;

  /**
   * 鑿滃崟鏄惁瑕嗙洊鍦ㄨЕ鍙戝畠鐨勫厓绱犵殑涓婇潰锛岄粯璁や负 `auto`
   * 涓 `true` 鏃讹紝浣胯彍鍗曡鐩栧湪瑙﹀彂瀹冪殑鍏冪礌鐨勪笂闈n   * 涓 `false` 鏃讹紝浣胯彍鍗曚笉瑕嗙洊瑙﹀彂瀹冪殑鍏冪礌
   * 涓 `auto` 鏃讹紝绠€鍗曡彍鍗曡鐩栬Е鍙戝畠鐨勫厓绱犮€傜骇鑱旇彍鍗曚笉瑕嗙洊瑙﹀彂瀹冪殑鍏冪礌
   */
  covered?: boolean | 'auto';

  /**
   * 瀛愯彍鍗曠殑瑙﹀彂鏂瑰紡锛岄粯璁や负 `hover`
   * 涓 `click` 鏃讹紝鐐瑰嚮鏃惰Е鍙戝瓙鑿滃崟
   * 涓 `hover` 鏃讹紝榧犳爣鎮诞鏃惰Е鍙戝瓙鑿滃崟
   */
  subMenuTrigger?: 'click' | 'hover';

  /**
   * 瀛愯彍鍗曠殑瑙﹀彂寤惰繜鏃堕棿锛堝崟浣嶏細姣锛夛紝鍙湁鍦 `subMenuTrigger: hover` 鏃讹紝杩欎釜鍙傛暟鎵嶆湁鏁堬紝榛樿涓 `200`
   */
  subMenuDelay?: number;
};

type EVENT = 'open' | 'opened' | 'close' | 'closed';
type STATE = 'opening' | 'opened' | 'closing' | 'closed';

const DEFAULT_OPTIONS: OPTIONS = {
  position: 'auto',
  align: 'auto',
  gutter: 16,
  fixed: false,
  covered: 'auto',
  subMenuTrigger: 'hover',
  subMenuDelay: 200,
};

class Menu {
  /**
   * 瑙﹀彂鑿滃崟鐨勫厓绱犵殑 JQ 瀵硅薄
   */
  public $anchor: JQ;

  /**
   * 鑿滃崟鍏冪礌鐨 JQ 瀵硅薄
   */
  public $element: JQ;

  /**
   * 閰嶇疆鍙傛暟
   */
  public options: OPTIONS = extend({}, DEFAULT_OPTIONS);

  /**
   * 褰撳墠鑿滃崟鐘舵€乗n   */
  private state: STATE = 'closed';

  /**
   * 鏄惁鏄骇鑱旇彍鍗昞n   */
  private isCascade: boolean;

  /**
   * 鑿滃崟鏄惁瑕嗙洊鍦ㄨЕ鍙戝畠鐨勫厓绱犵殑涓婇潰
   */
  private isCovered: boolean;

  public constructor(
    anchorSelector: Selector | HTMLElement | ArrayLike<HTMLElement>,
    menuSelector: Selector | HTMLElement | ArrayLike<HTMLElement>,
    options: OPTIONS = {},
  ) {
    this.$anchor = $(anchorSelector).first();
    this.$element = $(menuSelector).first();

    // 瑙﹀彂鑿滃崟鐨勫厓绱 鍜 鑿滃崟蹇呴』鏄悓绾х殑鍏冪礌锛屽惁鍒欒彍鍗曞彲鑳戒笉鑳藉畾浣峔n    if (!this.$anchor.parent().is(this.$element.parent())) {
      throw new Error('anchorSelector and menuSelector must be siblings');
    }

    extend(this.options, options);

    // 鏄惁鏄骇鑱旇彍鍗昞n    this.isCascade = this.$element.hasClass('mdui-menu-cascade');

    // covered 鍙傛暟澶勭悊
    this.isCovered =
      this.options.covered === 'auto' ? !this.isCascade : this.options.covered!;

    // 鐐瑰嚮瑙﹀彂鑿滃崟鍒囨崲
    this.$anchor.on('click', () => this.toggle());

    // 鐐瑰嚮鑿滃崟澶栭潰鍖哄煙鍏抽棴鑿滃崟
    $document.on('click touchstart', (event: Event) => {
      const $target = $(event.target as HTMLElement);

      if (
        this.isOpen() &&
        !$target.is(this.$element) &&
        !contains(this.$element[0], $target[0]) &&
        !$target.is(this.$anchor) &&
        !contains(this.$anchor[0], $target[0])
      ) {
        this.close();
      }
    });

    // 鐐瑰嚮涓嶅惈瀛愯彍鍗曠殑鑿滃崟鏉＄洰鍏抽棴鑿滃崟
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    $document.on('click', '.mdui-menu-item', function () {
      const $item = $(this);

      if (
        !$item.find('.mdui-menu').length &&
        $item.attr('disabled') === undefined
      ) {
        that.close();
      }
    });

    // 缁戝畾鐐瑰嚮鎴栭紶鏍囩Щ鍏ュ惈瀛愯彍鍗曠殑鏉＄洰鐨勪簨浠禱n    this.bindSubMenuEvent();

    // 绐楀彛澶у皬鍙樺寲鏃讹紝閲嶆柊璋冩暣鑿滃崟浣嶇疆
    $window.on(
      'resize',
      $.throttle(() => this.readjust(), 100),
    );
  }

  /**
   * 鏄惁涓烘墦寮€鐘舵€乗n   */
  private isOpen(): boolean {
    return this.state === 'opening' || this.state === 'opened';
  }

  /**
   * 瑙﹀彂缁勪欢浜嬩欢
   * @param name
   */
  private triggerEvent(name: EVENT): void {
    componentEvent(name, 'menu', this.$element, this);
  }

  /**
   * 璋冩暣涓昏彍鍗曚綅缃甛n   */
  private readjust(): void {
    let menuLeft;
    let menuTop;

    // 鑿滃崟浣嶇疆鍜屾柟鍚慭n    let position: 'bottom' | 'top' | 'center';
    let align: 'left' | 'right' | 'center';

    // window 绐楀彛鐨勫搴﹀拰楂樺害
    const windowHeight = $window.height();
    const windowWidth = $window.width();

    // 閰嶇疆鍙傛暟
    const gutter = this.options.gutter!;
    const isCovered = this.isCovered;
    const isFixed = this.options.fixed;

    // 鍔ㄧ敾鏂瑰悜鍙傛暟
    let transformOriginX;
    let transformOriginY;

    // 鑿滃崟鐨勫師濮嬪搴﹀拰楂樺害
    const menuWidth = this.$element.width();
    const menuHeight = this.$element.height();

    // 瑙﹀彂鑿滃崟鐨勫厓绱犲湪绐楀彛涓殑浣嶇疆
    const anchorRect = this.$anchor[0].getBoundingClientRect();
    const anchorTop = anchorRect.top;
    const anchorLeft = anchorRect.left;
    const anchorHeight = anchorRect.height;
    const anchorWidth = anchorRect.width;
    const anchorBottom = windowHeight - anchorTop - anchorHeight;
    const anchorRight = windowWidth - anchorLeft - anchorWidth;

    // 瑙﹀彂鍏冪礌鐩稿鍏舵嫢鏈夊畾浣嶅睘鎬х殑鐖跺厓绱犵殑浣嶇疆
    const anchorOffsetTop = this.$anchor[0].offsetTop;
    const anchorOffsetLeft = this.$anchor[0].offsetLeft;

    // 鑷姩鍒ゆ柇鑿滃崟浣嶇疆
    if (this.options.position === 'auto') {
      if (anchorBottom + (isCovered ? anchorHeight : 0) > menuHeight + gutter) {
        // 鍒ゆ柇涓嬫柟鏄惁鏀惧緱涓嬭彍鍗昞n        position = 'bottom';
      } else if (
        anchorTop + (isCovered ? anchorHeight : 0) >
        menuHeight + gutter
      ) {
        // 鍒ゆ柇涓婃柟鏄惁鏀惧緱涓嬭彍鍗昞n        position = 'top';
      } else {
        // 涓婁笅閮芥斁涓嶄笅锛屽眳涓樉绀篭n        position = 'center';
      }
    } else {
      position = this.options.position!;
    }

    // 鑷姩鍒ゆ柇鑿滃崟瀵归綈鏂瑰紡
    if (this.options.align === 'auto') {
      if (anchorRight + anchorWidth > menuWidth + gutter) {
        // 鍒ゆ柇鍙充晶鏄惁鏀惧緱涓嬭彍鍗昞n        align = 'left';
      } else if (anchorLeft + anchorWidth > menuWidth + gutter) {
        // 鍒ゆ柇宸︿晶鏄惁鏀惧緱涓嬭彍鍗昞n        align = 'right';
      } else {
        // 宸﹀彸閮芥斁涓嶄笅锛屽眳涓樉绀篭n        align = 'center';
      }
    } else {
      align = this.options.align!;
    }

    // 璁剧疆鑿滃崟浣嶇疆
    if (position === 'bottom') {
      transformOriginY = '0';
      menuTop =
        (isCovered ? 0 : anchorHeight) +
        (isFixed ? anchorTop : anchorOffsetTop);
    } else if (position === 'top') {
      transformOriginY = '100%';
      menuTop =
        (isCovered ? anchorHeight : 0) +
        (isFixed ? anchorTop - menuHeight : anchorOffsetTop - menuHeight);
    } else {
      transformOriginY = '50%';

      // =====================鍦ㄧ獥鍙ｄ腑灞呬腑
      // 鏄剧ず鐨勮彍鍗曠殑楂樺害锛岀畝鍗曡彍鍗曢珮搴︿笉瓒呰繃绐楀彛楂樺害锛岃嫢瓒呰繃浜嗗垯鍦ㄨ彍鍗曞唴閮ㄦ樉绀烘粴鍔ㄦ潯
      // 绾ц仈鑿滃崟鍐呴儴涓嶅厑璁稿嚭鐜版粴鍔ㄦ潯
      let menuHeightTemp = menuHeight;

      // 绠€鍗曡彍鍗曟瘮绐楀彛楂樻椂锛岄檺鍒惰彍鍗曢珮搴n      if (!this.isCascade) {
        if (menuHeight + gutter * 2 > windowHeight) {
          menuHeightTemp = windowHeight - gutter * 2;
          this.$element.height(menuHeightTemp);
        }
      }

      menuTop =
        (windowHeight - menuHeightTemp) / 2 +
        (isFixed ? 0 : anchorOffsetTop - anchorTop);
    }

    this.$element.css('top', `${menuTop}px`);

    // 璁剧疆鑿滃崟瀵归綈鏂瑰紡
    if (align === 'left') {
      transformOriginX = '0';
      menuLeft = isFixed ? anchorLeft : anchorOffsetLeft;
    } else if (align === 'right') {
      transformOriginX = '100%';
      menuLeft = isFixed
        ? anchorLeft + anchorWidth - menuWidth
        : anchorOffsetLeft + anchorWidth - menuWidth;
    } else {
      transformOriginX = '50%';

      //=======================鍦ㄧ獥鍙ｄ腑灞呬腑
      // 鏄剧ず鐨勮彍鍗曠殑瀹藉害锛岃彍鍗曞搴︿笉鑳借秴杩囩獥鍙ｅ搴n      let menuWidthTemp = menuWidth;

      // 鑿滃崟姣旂獥鍙ｅ锛岄檺鍒惰彍鍗曞搴n      if (menuWidth + gutter * 2 > windowWidth) {
        menuWidthTemp = windowWidth - gutter * 2;
        this.$element.width(menuWidthTemp);
      }

      menuLeft =
        (windowWidth - menuWidthTemp) / 2 +
        (isFixed ? 0 : anchorOffsetLeft - anchorLeft);
    }

    this.$element.css('left', `${menuLeft}px`);

    // 璁剧疆鑿滃崟鍔ㄧ敾鏂瑰悜
    this.$element.transformOrigin(`${transformOriginX} ${transformOriginY}`);
  }

  /**
   * 璋冩暣瀛愯彍鍗曠殑浣嶇疆
   * @param $submenu
   */
  private readjustSubmenu($submenu: JQ): void {
    const $item = $submenu.parent('.mdui-menu-item');

    let submenuTop;
    let submenuLeft;

    // 瀛愯彍鍗曚綅缃拰鏂瑰悜
    let position: 'top' | 'bottom';
    let align: 'left' | 'right';

    // window 绐楀彛鐨勫搴﹀拰楂樺害
    const windowHeight = $window.height();
    const windowWidth = $window.width();

    // 鍔ㄧ敾鏂瑰悜鍙傛暟
    let transformOriginX;
    let transformOriginY;

    // 瀛愯彍鍗曠殑鍘熷瀹藉害鍜岄珮搴n    const submenuWidth = $submenu.width();
    const submenuHeight = $submenu.height();

    // 瑙﹀彂瀛愯彍鍗曠殑鑿滃崟椤圭殑瀹藉害楂樺害
    const itemRect = $item[0].getBoundingClientRect();
    const itemWidth = itemRect.width;
    const itemHeight = itemRect.height;
    const itemLeft = itemRect.left;
    const itemTop = itemRect.top;

    // 鍒ゆ柇鑿滃崟涓婁笅浣嶇疆
    if (windowHeight - itemTop > submenuHeight) {
      // 鍒ゆ柇涓嬫柟鏄惁鏀惧緱涓嬭彍鍗昞n      position = 'bottom';
    } else if (itemTop + itemHeight > submenuHeight) {
      // 鍒ゆ柇涓婃柟鏄惁鏀惧緱涓嬭彍鍗昞n      position = 'top';
    } else {
      // 榛樿鏀惧湪涓嬫柟
      position = 'bottom';
    }

    // 鍒ゆ柇鑿滃崟宸﹀彸浣嶇疆
    if (windowWidth - itemLeft - itemWidth > submenuWidth) {
      // 鍒ゆ柇鍙充晶鏄惁鏀惧緱涓嬭彍鍗昞n      align = 'left';
    } else if (itemLeft > submenuWidth) {
      // 鍒ゆ柇宸︿晶鏄惁鏀惧緱涓嬭彍鍗昞n      align = 'right';
    } else {
      // 榛樿鏀惧湪鍙充晶
      align = 'left';
    }

    // 璁剧疆鑿滃崟浣嶇疆
    if (position === 'bottom') {
      transformOriginY = '0';
      submenuTop = '0';
    } else if (position === 'top') {
      transformOriginY = '100%';
      submenuTop = -submenuHeight + itemHeight;
    }

    $submenu.css('top', `${submenuTop}px`);

    // 璁剧疆鑿滃崟瀵归綈鏂瑰紡
    if (align === 'left') {
      transformOriginX = '0';
      submenuLeft = itemWidth;
    } else if (align === 'right') {
      transformOriginX = '100%';
      submenuLeft = -submenuWidth;
    }

    $submenu.css('left', `${submenuLeft}px`);

    // 璁剧疆鑿滃崟鍔ㄧ敾鏂瑰悜
    $submenu.transformOrigin(`${transformOriginX} ${transformOriginY}`);
  }

  /**
   * 鎵撳紑瀛愯彍鍗昞n   * @param $submenu
   */
  private openSubMenu($submenu: JQ): void {
    this.readjustSubmenu($submenu);

    $submenu
      .addClass('mdui-menu-open')
      .parent('.mdui-menu-item')
      .addClass('mdui-menu-item-active');
  }

  /**
   * 鍏抽棴瀛愯彍鍗曪紝鍙婂叾宓屽鐨勫瓙鑿滃崟
   * @param $submenu
   */
  private closeSubMenu($submenu: JQ): void {
    // 鍏抽棴瀛愯彍鍗昞n    $submenu
      .removeClass('mdui-menu-open')
      .addClass('mdui-menu-closing')
      .transitionEnd(() => $submenu.removeClass('mdui-menu-closing'))

      // 绉婚櫎婵€娲荤姸鎬佺殑鏍峰紡
      .parent('.mdui-menu-item')
      .removeClass('mdui-menu-item-active');

    // 寰幆鍏抽棴宓屽鐨勫瓙鑿滃崟
    $submenu.find('.mdui-menu').each((_, menu) => {
      const $subSubmenu = $(menu);

      $subSubmenu
        .removeClass('mdui-menu-open')
        .addClass('mdui-menu-closing')
        .transitionEnd(() => $subSubmenu.removeClass('mdui-menu-closing'))
        .parent('.mdui-menu-item')
        .removeClass('mdui-menu-item-active');
    });
  }

  /**
   * 鍒囨崲瀛愯彍鍗曠姸鎬乗n   * @param $submenu
   */
  private toggleSubMenu($submenu: JQ): void {
    $submenu.hasClass('mdui-menu-open')
      ? this.closeSubMenu($submenu)
      : this.openSubMenu($submenu);
  }

  /**
   * 缁戝畾瀛愯彍鍗曚簨浠禱n   */
  private bindSubMenuEvent(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    // 鐐瑰嚮鎵撳紑瀛愯彍鍗昞n    this.$element.on('click', '.mdui-menu-item', function (event) {
      const $item = $(this as HTMLElement);
      const $target = $(event.target as HTMLElement);

      // 绂佺敤鐘舵€佽彍鍗曚笉鎿嶄綔
      if ($item.attr('disabled') !== undefined) {
        return;
      }

      // 娌℃湁鐐瑰嚮鍦ㄥ瓙鑿滃崟鐨勮彍鍗曢」涓婃椂锛屼笉鎿嶄綔锛堢偣鍦ㄤ簡瀛愯彍鍗曠殑绌虹櫧鍖哄煙銆佹垨鍒嗛殧绾夸笂锛塡n      if ($target.is('.mdui-menu') || $target.is('.mdui-divider')) {
        return;
      }

      // 闃绘鍐掓场锛岀偣鍑昏彍鍗曢」鏃跺彧鍦ㄦ渶鍚庝竴绾х殑 mdui-menu-item 涓婄敓鏁堬紝涓嶅悜涓婂啋娉n      if (!$target.parents('.mdui-menu-item').first().is($item)) {
        return;
      }

      // 褰撳墠鑿滃崟鐨勫瓙鑿滃崟
      const $submenu = $item.children('.mdui-menu');

      // 鍏堝叧闂櫎褰撳墠瀛愯彍鍗曞鐨勬墍鏈夊悓绾у瓙鑿滃崟
      $item
        .parent('.mdui-menu')
        .children('.mdui-menu-item')
        .each((_, item) => {
          const $tmpSubmenu = $(item).children('.mdui-menu');

          if (
            $tmpSubmenu.length &&
            (!$submenu.length || !$tmpSubmenu.is($submenu))
          ) {
            that.closeSubMenu($tmpSubmenu);
          }
        });

      // 鍒囨崲褰撳墠瀛愯彍鍗昞n      if ($submenu.length) {
        that.toggleSubMenu($submenu);
      }
    });

    if (this.options.subMenuTrigger === 'hover') {
      // 涓存椂瀛樺偍 setTimeout 瀵硅薄
      let timeout: any = null;
      let timeoutOpen: any = null;

      this.$element.on('mouseover mouseout', '.mdui-menu-item', function (
        event,
      ) {
        const $item = $(this as HTMLElement);
        const eventType = event.type;
        const $relatedTarget = $(
          (event as MouseEvent).relatedTarget as HTMLElement,
        );

        // 绂佺敤鐘舵€佺殑鑿滃崟涓嶆搷浣淺n        if ($item.attr('disabled') !== undefined) {
          return;
        }

        // 鐢 mouseover 妯℃嫙 mouseenter
        if (eventType === 'mouseover') {
          if (
            !$item.is($relatedTarget) &&
            contains($item[0], $relatedTarget[0])
          ) {
            return;
          }
        }

        // 鐢 mouseout 妯℃嫙 mouseleave
        else if (eventType === 'mouseout') {
          if (
            $item.is($relatedTarget) ||
            contains($item[0], $relatedTarget[0])
          ) {
            return;
          }
        }

        // 褰撳墠鑿滃崟椤逛笅鐨勫瓙鑿滃崟锛屾湭蹇呭瓨鍦╘n        const $submenu = $item.children('.mdui-menu');

        // 榧犳爣绉诲叆鑿滃崟椤规椂锛屾樉绀鸿彍鍗曢」涓嬬殑瀛愯彍鍗昞n        if (eventType === 'mouseover') {
          if ($submenu.length) {
            // 褰撳墠瀛愯彍鍗曞噯澶囨墦寮€鏃讹紝濡傛灉褰撳墠瀛愯彍鍗曟鍑嗗鐫€鍏抽棴锛屼笉鐢ㄥ啀鍏抽棴浜哱n            const tmpClose = $submenu.data('timeoutClose.mdui.menu');
            if (tmpClose) {
              clearTimeout(tmpClose);
            }

            // 濡傛灉褰撳墠瀛愯彍鍗曞凡缁忔墦寮€锛屼笉鎿嶄綔
            if ($submenu.hasClass('mdui-menu-open')) {
              return;
            }

            // 褰撳墠瀛愯彍鍗曞噯澶囨墦寮€鏃讹紝鍏朵粬鍑嗗鎵撳紑鐨勫瓙鑿滃崟涓嶇敤鍐嶆墦寮€浜哱n            clearTimeout(timeoutOpen);

            // 鍑嗗鎵撳紑褰撳墠瀛愯彍鍗昞n            timeout = timeoutOpen = setTimeout(
              () => that.openSubMenu($submenu),
              that.options.subMenuDelay,
            );

            $submenu.data('timeoutOpen.mdui.menu', timeout);
          }
        }

        // 榧犳爣绉诲嚭鑿滃崟椤规椂锛屽叧闂彍鍗曢」涓嬬殑瀛愯彍鍗昞n        else if (eventType === 'mouseout') {
          if ($submenu.length) {
            // 榧犳爣绉诲嚭鑿滃崟椤规椂锛屽鏋滃綋鍓嶈彍鍗曢」涓嬬殑瀛愯彍鍗曟鍑嗗鎵撳紑锛屼笉鐢ㄥ啀鎵撳紑浜哱n            const tmpOpen = $submenu.data('timeoutOpen.mdui.menu');
            if (tmpOpen) {
              clearTimeout(tmpOpen);
            }

            // 鍑嗗鍏抽棴褰撳墠瀛愯彍鍗昞n            timeout = setTimeout(
              () => that.closeSubMenu($submenu),
              that.options.subMenuDelay,
            );

            $submenu.data('timeoutClose.mdui.menu', timeout);
          }
        }
      });
    }
  }

  /**
   * 鍔ㄧ敾缁撴潫鍥炶皟
   */
  private transitionEnd(): void {
    this.$element.removeClass('mdui-menu-closing');

    if (this.state === 'opening') {
      this.state = 'opened';
      this.triggerEvent('opened');
    }

    if (this.state === 'closing') {
      this.state = 'closed';
      this.triggerEvent('closed');

      // 鍏抽棴鍚庯紝鎭㈠鑿滃崟鏍峰紡鍒伴粯璁ょ姸鎬侊紝骞舵仮澶 fixed 瀹氫綅
      this.$element.css({
        top: '',
        left: '',
        width: '',
        position: 'fixed',
      });
    }
  }

  /**
   * 鍒囨崲鑿滃崟鐘舵€乗n   */
  public toggle(): void {
    this.isOpen() ? this.close() : this.open();
  }

  /**
   * 鎵撳紑鑿滃崟
   */
  public open(): void {
    if (this.isOpen()) {
      return;
    }

    this.state = 'opening';
    this.triggerEvent('open');

    this.readjust();

    this.$element
      // 鑿滃崟闅愯棌鐘舵€佷娇鐢ㄤ娇鐢 fixed 瀹氫綅銆俓n      .css('position', this.options.fixed ? 'fixed' : 'absolute')
      .addClass('mdui-menu-open')
      .transitionEnd(() => this.transitionEnd());
  }

  /**
   * 鍏抽棴鑿滃崟
   */
  public close(): void {
    if (!this.isOpen()) {
      return;
    }

    this.state = 'closing';
    this.triggerEvent('close');

    // 鑿滃崟寮€濮嬪叧闂椂锛屽叧闂墍鏈夊瓙鑿滃崟
    this.$element.find('.mdui-menu').each((_, submenu) => {
      this.closeSubMenu($(submenu));
    });

    this.$element
      .removeClass('mdui-menu-open')
      .addClass('mdui-menu-closing')
      .transitionEnd(() => this.transitionEnd());
  }
}

mdui.Menu = Menu;
