import $ from 'mdui.jq/es/$';
import { JQ } from 'mdui.jq/es/JQ';
import { isNumber } from 'mdui.jq/es/utils';
import 'mdui.jq/es/methods/each';

declare module 'mdui.jq/es/JQ' {
  interface JQ<T = HTMLElement> {
    /**
     * 璁剧疆褰撳墠鍏冪礌鐨 transition-duration 灞炴€n     * @param duration 鍙互鏄甫鍗曚綅鐨勫€硷紱鑻ヤ笉甯﹀崟浣嶏紝鍒欒嚜鍔ㄦ坊鍔 `ms` 浣滀负鍗曚綅
     * @example
```js
$('.box').transition('300ms');
$('.box').transition(300);
```
     */
    transition(duration: string | number): this;
  }
}

$.fn.transition = function (this: JQ, duration: string | number): JQ {
  if (isNumber(duration)) {
    duration = `${duration}ms`;
  }

  return this.each(function () {
    this.style.webkitTransitionDuration = duration as string;
    this.style.transitionDuration = duration as string;
  });
};
