import $ from 'mdui.jq/es/$';
import { isUndefined } from 'mdui.jq/es/utils';
import PlainObject from 'mdui.jq/es/interfaces/PlainObject';

declare module 'mdui.jq/es/interfaces/JQStatic' {
  interface JQStatic {
    /**
     * 鐢熸垚涓€涓叏灞€鍞竴鐨 ID
     * @param name 褰撹鍙傛暟鍊煎搴旂殑 guid 涓嶅瓨鍦ㄦ椂锛屼細鐢熸垚涓€涓柊鐨 guid锛屽苟杩斿洖锛涘綋璇ュ弬鏁板搴旂殑 guid 宸插瓨鍦紝鍒欑洿鎺ヨ繑鍥炲凡鏈 guid
     * @example
```js
$.guid();
```
     * @example
```js
$.guid('test');
```
     */
    guid(name?: string): string;
  }
}

const GUID: PlainObject<string> = {};

$.guid = function (name?: string): string {
  if (!isUndefined(name) && !isUndefined(GUID[name])) {
    return GUID[name];
  }

  function s4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  const guid =
    '_' +
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4();

  if (!isUndefined(name)) {
    GUID[name] = guid;
  }

  return guid;
};
