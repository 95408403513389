import $ from 'mdui.jq/es/$';
import extend from 'mdui.jq/es/functions/extend';
import { JQ } from 'mdui.jq/es/JQ';
import 'mdui.jq/es/methods/addClass';
import 'mdui.jq/es/methods/appendTo';
import 'mdui.jq/es/methods/find';
import 'mdui.jq/es/methods/hasClass';
import 'mdui.jq/es/methods/off';
import 'mdui.jq/es/methods/on';
import 'mdui.jq/es/methods/parents';
import 'mdui.jq/es/methods/remove';
import { isString } from 'mdui.jq/es/utils';
import mdui from '../../mdui';
import '../../jq_extends/methods/reflow';
import '../../jq_extends/methods/transform';
import '../../jq_extends/methods/transitionEnd';
import { $document } from '../../utils/dom';
import { dequeue, queue } from '../../utils/queue';
import { startEvent } from '../../utils/touchHandler';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * 鎵撳紑涓€涓 Snackbar
     * @param message Snackbar 鐨勬枃鏈琝n     * @param options 閰嶇疆鍙傛暟
     */
    snackbar(message: string, options?: OPTIONS): Snackbar;

    /**
     * 鎵撳紑涓€涓 Snackbar
     * @param options 閰嶇疆鍙傛暟
     */
    snackbar(options: OPTIONS): Snackbar;
  }
}

type OPTIONS = {
  /**
   * Snackbar 鐨勬枃鏈€傞€氳繃 `mdui.snackbar(options)` 璋冪敤鏃讹紝璇ュ弬鏁颁笉鑳戒负绌篭n   */
  message?: string;

  /**
   * 鍦ㄧ敤鎴锋病鏈夋搷浣滄椂澶氶暱鏃堕棿鑷姩闅愯棌锛屽崟浣嶏紙姣锛夈€備负 `0` 鏃惰〃绀轰笉鑷姩鍏抽棴锛岄粯璁や负 `4000`
   */
  timeout?: number;

  /**
   * Snackbar 鐨勪綅缃紝榛樿涓 `bottom`銆俓n   * 鍙栧€艰寖鍥村寘鎷細
   *   `bottom`: 涓嬫柟
   *   `top`: 涓婃柟
   *   `left-top`: 宸︿笂瑙抃n   *   `left-bottom`: 宸︿笅瑙抃n   *   `right-top`: 鍙充笂瑙抃n   *   `right-bottom`: 鍙充笅瑙抃n   */
  position?:
    | 'bottom'
    | 'top'
    | 'left-top'
    | 'left-bottom'
    | 'right-top'
    | 'right-bottom';

  /**
   * 鎸夐挳鐨勬枃鏈琝n   */
  buttonText?: string;

  /**
   * 鎸夐挳鐨勬枃鏈鑹诧紝鍙互鏄鑹插悕鎴栭鑹插€硷紝濡 `red`銆乣#ffffff`銆乣rgba(255, 255, 255, 0.3)` 绛夈€傞粯璁や负 `#90CAF9`
   */
  buttonColor?: string;

  /**
   * 鐐瑰嚮鎸夐挳鏃舵槸鍚﹀叧闂 Snackbar锛岄粯璁や负 `true`
   */
  closeOnButtonClick?: boolean;

  /**
   * 鐐瑰嚮鎴栬Е鎽 Snackbar 浠ュ鐨勫尯鍩熸椂鏄惁鍏抽棴 Snackbar锛岄粯璁や负 `true`
   */
  closeOnOutsideClick?: boolean;

  /**
   * 鍦 Snackbar 涓婄偣鍑荤殑鍥炶皟鍑芥暟锛屽弬鏁颁负 Snackbar 鐨勫疄渚媆n   */
  onClick?: (snackbar: Snackbar) => void;

  /**
   * 鐐瑰嚮 Snackbar 涓婄殑鎸夐挳鏃剁殑鍥炶皟鍑芥暟锛屽弬鏁颁负 Snackbar 鐨勫疄渚媆n   */
  onButtonClick?: (snackbar: Snackbar) => void;

  /**
   * Snackbar 寮€濮嬫墦寮€鏃剁殑鍥炶皟鍑芥暟锛屽弬鏁颁负 Snackbar 鐨勫疄渚媆n   */
  onOpen?: (snackbar: Snackbar) => void;

  /**
   * Snackbar 鎵撳紑鍚庣殑鍥炶皟鍑芥暟锛屽弬鏁颁负 Snackbar 鐨勫疄渚媆n   */
  onOpened?: (snackbar: Snackbar) => void;

  /**
   * Snackbar 寮€濮嬪叧闂椂鐨勫洖璋冨嚱鏁帮紝鍙傛暟涓 Snackbar 鐨勫疄渚媆n   */
  onClose?: (snackbar: Snackbar) => void;

  /**
   * Snackbar 鍏抽棴鍚庣殑鍥炶皟鍑芥暟锛屽弬鏁颁负 Snackbar 鐨勫疄渚媆n   */
  onClosed?: (snackbar: Snackbar) => void;
};

type STATE = 'opening' | 'opened' | 'closing' | 'closed';

const DEFAULT_OPTIONS: OPTIONS = {
  message: '',
  timeout: 4000,
  position: 'bottom',
  buttonText: '',
  buttonColor: '',
  closeOnButtonClick: true,
  closeOnOutsideClick: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onButtonClick: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onOpen: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onOpened: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClosed: () => {},
};

/**
 * 褰撳墠鎵撳紑鐫€鐨 Snackbar
 */
let currentInst: null | Snackbar = null;

/**
 * 闃熷垪鍚峔n */
const queueName = '_mdui_snackbar';

class Snackbar {
  /**
   * Snackbar 鍏冪礌
   */
  public $element: JQ;
  /**
   * 閰嶇疆鍙傛暟
   */
  public options: OPTIONS = extend({}, DEFAULT_OPTIONS);

  /**
   * 褰撳墠 Snackbar 鐨勭姸鎬乗n   */
  private state: STATE = 'closed';

  /**
   * setTimeout 鐨 ID
   */
  private timeoutId: any = null;

  public constructor(options: OPTIONS) {
    extend(this.options, options);

    // 鎸夐挳棰滆壊
    let buttonColorStyle = '';
    let buttonColorClass = '';

    if (
      this.options.buttonColor!.indexOf('#') === 0 ||
      this.options.buttonColor!.indexOf('rgb') === 0
    ) {
      buttonColorStyle = `style="color:${this.options.buttonColor}"`;
    } else if (this.options.buttonColor !== '') {
      buttonColorClass = `mdui-text-color-${this.options.buttonColor}`;
    }

    // 娣诲姞 HTML
    this.$element = $(
      '<div class="mdui-snackbar">' +
        `<div class="mdui-snackbar-text">${this.options.message}</div>` +
        (this.options.buttonText
          ? `<a href="javascript:void(0)" class="mdui-snackbar-action mdui-btn mdui-ripple mdui-ripple-white ${buttonColorClass}" ${buttonColorStyle}>${this.options.buttonText}</a>`
          : '') +
        '</div>',
    ).appendTo(document.body);

    // 璁剧疆浣嶇疆
    this.setPosition('close');

    this.$element.reflow().addClass(`mdui-snackbar-${this.options.position}`);
  }

  /**
   * 鐐瑰嚮 Snackbar 澶栭潰鐨勫尯鍩熷叧闂璡n   * @param event
   */
  private closeOnOutsideClick(event: Event): void {
    const $target = $(event.target as HTMLElement);

    if (
      !$target.hasClass('mdui-snackbar') &&
      !$target.parents('.mdui-snackbar').length
    ) {
      currentInst!.close();
    }
  }

  /**
   * 璁剧疆 Snackbar 鐨勪綅缃甛n   * @param state
   */
  private setPosition(state: 'open' | 'close'): void {
    const snackbarHeight = this.$element[0].clientHeight;
    const position = this.options.position;

    let translateX;
    let translateY;

    // translateX
    if (position === 'bottom' || position === 'top') {
      translateX = '-50%';
    } else {
      translateX = '0';
    }

    // translateY
    if (state === 'open') {
      translateY = '0';
    } else {
      if (position === 'bottom') {
        translateY = snackbarHeight;
      }

      if (position === 'top') {
        translateY = -snackbarHeight;
      }

      if (position === 'left-top' || position === 'right-top') {
        translateY = -snackbarHeight - 24;
      }

      if (position === 'left-bottom' || position === 'right-bottom') {
        translateY = snackbarHeight + 24;
      }
    }

    this.$element.transform(`translate(${translateX},${translateY}px`);
  }

  /**
   * 鎵撳紑 Snackbar
   */
  public open(): void {
    if (this.state === 'opening' || this.state === 'opened') {
      return;
    }

    // 濡傛灉褰撳墠鏈夋鍦ㄦ樉绀虹殑 Snackbar锛屽垯鍏堝姞鍏ラ槦鍒楋紝绛夋棫 Snackbar 鍏抽棴鍚庡啀鎵撳紑
    if (currentInst) {
      queue(queueName, () => this.open());
      return;
    }

    currentInst = this;

    // 寮€濮嬫墦寮€
    this.state = 'opening';
    this.options.onOpen!(this);

    this.setPosition('open');

    this.$element.transitionEnd(() => {
      if (this.state !== 'opening') {
        return;
      }

      this.state = 'opened';
      this.options.onOpened!(this);

      // 鏈夋寜閽椂缁戝畾浜嬩欢
      if (this.options.buttonText) {
        this.$element.find('.mdui-snackbar-action').on('click', () => {
          this.options.onButtonClick!(this);
          if (this.options.closeOnButtonClick) {
            this.close();
          }
        });
      }

      // 鐐瑰嚮 snackbar 鐨勪簨浠禱n      this.$element.on('click', (event) => {
        if (!$(event.target as HTMLElement).hasClass('mdui-snackbar-action')) {
          this.options.onClick!(this);
        }
      });

      // 鐐瑰嚮 Snackbar 澶栭潰鐨勫尯鍩熷叧闂璡n      if (this.options.closeOnOutsideClick) {
        $document.on(startEvent, this.closeOnOutsideClick);
      }

      // 瓒呮椂鍚庤嚜鍔ㄥ叧闂璡n      if (this.options.timeout) {
        this.timeoutId = setTimeout(() => this.close(), this.options.timeout);
      }
    });
  }

  /**
   * 鍏抽棴 Snackbar
   */
  public close(): void {
    if (this.state === 'closing' || this.state === 'closed') {
      return;
    }

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    if (this.options.closeOnOutsideClick) {
      $document.off(startEvent, this.closeOnOutsideClick);
    }

    this.state = 'closing';
    this.options.onClose!(this);

    this.setPosition('close');

    this.$element.transitionEnd(() => {
      if (this.state !== 'closing') {
        return;
      }

      currentInst = null;
      this.state = 'closed';
      this.options.onClosed!(this);
      this.$element.remove();
      dequeue(queueName);
    });
  }
}

mdui.snackbar = function (message: any, options: any = {}): Snackbar {
  if (isString(message)) {
    options.message = message;
  } else {
    options = message;
  }

  const instance = new Snackbar(options);

  instance.open();

  return instance;
};
