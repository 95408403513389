import $ from 'mdui.jq/es/$';
import contains from 'mdui.jq/es/functions/contains';
import extend from 'mdui.jq/es/functions/extend';
import { JQ } from 'mdui.jq/es/JQ';
import 'mdui.jq/es/methods/addClass';
import 'mdui.jq/es/methods/append';
import 'mdui.jq/es/methods/children';
import 'mdui.jq/es/methods/css';
import 'mdui.jq/es/methods/each';
import 'mdui.jq/es/methods/find';
import 'mdui.jq/es/methods/first';
import 'mdui.jq/es/methods/hasClass';
import 'mdui.jq/es/methods/height';
import 'mdui.jq/es/methods/hide';
import 'mdui.jq/es/methods/innerHeight';
import 'mdui.jq/es/methods/off';
import 'mdui.jq/es/methods/on';
import 'mdui.jq/es/methods/remove';
import 'mdui.jq/es/methods/removeClass';
import 'mdui.jq/es/methods/show';
import Selector from 'mdui.jq/es/types/Selector';
import '../../jq_extends/methods/transitionEnd';
import '../../jq_extends/static/hideOverlay';
import '../../jq_extends/static/lockScreen';
import '../../jq_extends/static/showOverlay';
import '../../jq_extends/static/throttle';
import '../../jq_extends/static/unlockScreen';
import { componentEvent } from '../../utils/componentEvent';
import { $body, $window } from '../../utils/dom';
import { dequeue, queue } from '../../utils/queue';

type OPTIONS = {
  /**
   * 鎵撳紑瀵硅瘽妗嗘椂鏄惁娣诲姞 url hash锛岃嫢涓 `true`锛屽垯鎵撳紑瀵硅瘽妗嗗悗鍙敤杩囨祻瑙堝櫒鐨勫悗閫€鎸夐挳鎴 Android 鐨勮繑鍥為敭鍏抽棴瀵硅瘽妗嗐€俓n   */
  history?: boolean;

  /**
   * 鎵撳紑瀵硅瘽妗嗘椂鏄惁鏄剧ず閬僵銆俓n   */
  overlay?: boolean;

  /**
   * 鏄惁妯℃€佸寲瀵硅瘽妗嗐€備负 `false` 鏃剁偣鍑诲璇濇澶栭潰鐨勫尯鍩熸椂鍏抽棴瀵硅瘽妗嗭紝鍚﹀垯涓嶅叧闂€俓n   */
  modal?: boolean;

  /**
   * 鎸変笅 Esc 閿椂鏄惁鍏抽棴瀵硅瘽妗嗐€俓n   */
  closeOnEsc?: boolean;

  /**
   * 鎸変笅鍙栨秷鎸夐挳鏃舵槸鍚﹀叧闂璇濇銆俓n   */
  closeOnCancel?: boolean;

  /**
   * 鎸変笅纭鎸夐挳鏃舵槸鍚﹀叧闂璇濇銆俓n   */
  closeOnConfirm?: boolean;

  /**
   * 鍏抽棴瀵硅瘽妗嗗悗鏄惁鑷姩閿€姣佸璇濇銆俓n   */
  destroyOnClosed?: boolean;
};

type STATE = 'opening' | 'opened' | 'closing' | 'closed';
type EVENT = 'open' | 'opened' | 'close' | 'closed' | 'cancel' | 'confirm';

const DEFAULT_OPTIONS: OPTIONS = {
  history: true,
  overlay: true,
  modal: false,
  closeOnEsc: true,
  closeOnCancel: true,
  closeOnConfirm: true,
  destroyOnClosed: false,
};

/**
 * 褰撳墠鏄剧ず鐨勫璇濇瀹炰緥
 */
let currentInst: null | Dialog = null;

/**
 * 闃熷垪鍚峔n */
const queueName = '_mdui_dialog';

/**
 * 绐楀彛鏄惁宸查攣瀹歕n */
let isLockScreen = false;

/**
 * 閬僵灞傚厓绱燶n */
let $overlay: null | JQ;

class Dialog {
  /**
   * dialog 鍏冪礌鐨 JQ 瀵硅薄
   */
  public $element: JQ;

  /**
   * 閰嶇疆鍙傛暟
   */
  public options: OPTIONS = extend({}, DEFAULT_OPTIONS);

  /**
   * 褰撳墠 dialog 鐨勭姸鎬乗n   */
  public state: STATE = 'closed';

  /**
   * dialog 鍏冪礌鏄惁鏄姩鎬佹坊鍔犵殑
   */
  private append = false;

  public constructor(
    selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
    options: OPTIONS = {},
  ) {
    this.$element = $(selector).first();

    // 濡傛灉瀵硅瘽妗嗗厓绱犳病鏈夊湪褰撳墠鏂囨。涓紝鍒欓渶瑕佹坊鍔燶n    if (!contains(document.body, this.$element[0])) {
      this.append = true;
      $body.append(this.$element);
    }

    extend(this.options, options);

    // 缁戝畾鍙栨秷鎸夐挳浜嬩欢
    this.$element.find('[mdui-dialog-cancel]').each((_, cancel) => {
      $(cancel).on('click', () => {
        this.triggerEvent('cancel');

        if (this.options.closeOnCancel) {
          this.close();
        }
      });
    });

    // 缁戝畾纭鎸夐挳浜嬩欢
    this.$element.find('[mdui-dialog-confirm]').each((_, confirm) => {
      $(confirm).on('click', () => {
        this.triggerEvent('confirm');

        if (this.options.closeOnConfirm) {
          this.close();
        }
      });
    });

    // 缁戝畾鍏抽棴鎸夐挳浜嬩欢
    this.$element.find('[mdui-dialog-close]').each((_, close) => {
      $(close).on('click', () => this.close());
    });
  }

  /**
   * 瑙﹀彂缁勪欢浜嬩欢
   * @param name
   */
  private triggerEvent(name: EVENT): void {
    componentEvent(name, 'dialog', this.$element, this);
  }

  /**
   * 绐楀彛瀹藉害鍙樺寲锛屾垨瀵硅瘽妗嗗唴瀹瑰彉鍖栨椂锛岃皟鏁村璇濇浣嶇疆鍜屽璇濇鍐呯殑婊氬姩鏉n   */
  private readjust(): void {
    if (!currentInst) {
      return;
    }

    const $element = currentInst.$element;
    const $title = $element.children('.mdui-dialog-title');
    const $content = $element.children('.mdui-dialog-content');
    const $actions = $element.children('.mdui-dialog-actions');

    // 璋冩暣 dialog 鐨 top 鍜 height 鍊糪n    $element.height('');
    $content.height('');

    const elementHeight = $element.height();
    $element.css({
      top: `${($window.height() - elementHeight) / 2}px`,
      height: `${elementHeight}px`,
    });

    // 璋冩暣 mdui-dialog-content 鐨勯珮搴n    $content.innerHeight(
      elementHeight -
        ($title.innerHeight() || 0) -
        ($actions.innerHeight() || 0),
    );
  }

  /**
   * hashchange 浜嬩欢瑙﹀彂鏃跺叧闂璇濇
   */
  private hashchangeEvent(): void {
    if (window.location.hash.substring(1).indexOf('mdui-dialog') < 0) {
      currentInst!.close(true);
    }
  }

  /**
   * 鐐瑰嚮閬僵灞傚叧闂璇濇
   * @param event
   */
  private overlayClick(event: Event): void {
    if (
      $(event.target as HTMLElement).hasClass('mdui-overlay') &&
      currentInst
    ) {
      currentInst.close();
    }
  }

  /**
   * 鍔ㄧ敾缁撴潫鍥炶皟
   */
  private transitionEnd(): void {
    if (this.$element.hasClass('mdui-dialog-open')) {
      this.state = 'opened';
      this.triggerEvent('opened');
    } else {
      this.state = 'closed';
      this.triggerEvent('closed');
      this.$element.hide();

      // 鎵€鏈夊璇濇閮藉叧闂紝涓斿綋鍓嶆病鏈夋墦寮€鐨勫璇濇鏃讹紝瑙ｉ攣灞忓箷
      if (!queue(queueName).length && !currentInst && isLockScreen) {
        $.unlockScreen();
        isLockScreen = false;
      }

      $window.off('resize', $.throttle(this.readjust, 100));

      if (this.options.destroyOnClosed) {
        this.destroy();
      }
    }
  }

  /**
   * 鎵撳紑鎸囧畾瀵硅瘽妗哱n   */
  private doOpen(): void {
    currentInst = this;

    if (!isLockScreen) {
      $.lockScreen();
      isLockScreen = true;
    }

    this.$element.show();
    this.readjust();

    $window.on('resize', $.throttle(this.readjust, 100));

    // 鎵撳紑娑堟伅妗哱n    this.state = 'opening';
    this.triggerEvent('open');
    this.$element
      .addClass('mdui-dialog-open')
      .transitionEnd(() => this.transitionEnd());

    // 涓嶅瓨鍦ㄩ伄缃╁眰鍏冪礌鏃讹紝娣诲姞閬僵灞俓n    if (!$overlay) {
      $overlay = $.showOverlay(5100);
    }

    // 鐐瑰嚮閬僵灞傛椂鏄惁鍏抽棴瀵硅瘽妗哱n    if (this.options.modal) {
      $overlay.off('click', this.overlayClick);
    } else {
      $overlay.on('click', this.overlayClick);
    }

    // 鏄惁鏄剧ず閬僵灞傦紝涓嶆樉绀烘椂锛屾妸閬僵灞傝儗鏅€忔槑
    $overlay.css('opacity', this.options.overlay ? '' : 0);

    if (this.options.history) {
      // 濡傛灉 hash 涓師鏉ュ氨鏈 mdui-dialog锛屽厛鍒犻櫎锛岄伩鍏嶅悗閫€鍘嗗彶绾綍鍚庝粛鐒舵湁 mdui-dialog 瀵艰嚧鏃犳硶鍏抽棴
      // 鍖呮嫭 mdui-dialog 鍜 &mdui-dialog 鍜 ?mdui-dialog
      let hash = window.location.hash.substring(1);
      if (hash.indexOf('mdui-dialog') > -1) {
        hash = hash.replace(/[&?]?mdui-dialog/g, '');
      }

      // 鍚庨€€鎸夐挳鍏抽棴瀵硅瘽妗哱n      if (hash) {
        window.location.hash = `${hash}${
          hash.indexOf('?') > -1 ? '&' : '?'
        }mdui-dialog`;
      } else {
        window.location.hash = 'mdui-dialog';
      }

      $window.on('hashchange', this.hashchangeEvent);
    }
  }

  /**
   * 褰撳墠瀵硅瘽妗嗘槸鍚︿负鎵撳紑鐘舵€乗n   */
  private isOpen(): boolean {
    return this.state === 'opening' || this.state === 'opened';
  }

  /**
   * 鎵撳紑瀵硅瘽妗哱n   */
  public open(): void {
    if (this.isOpen()) {
      return;
    }

    // 濡傛灉褰撳墠鏈夋鍦ㄦ墦寮€鎴栧凡缁忔墦寮€鐨勫璇濇,鎴栭槦鍒椾笉涓虹┖锛屽垯鍏堝姞鍏ラ槦鍒楋紝绛夋棫瀵硅瘽妗嗗紑濮嬪叧闂椂鍐嶆墦寮€
    if (
      (currentInst &&
        (currentInst.state === 'opening' || currentInst.state === 'opened')) ||
      queue(queueName).length
    ) {
      queue(queueName, () => this.doOpen());

      return;
    }

    this.doOpen();
  }

  /**
   * 鍏抽棴瀵硅瘽妗哱n   */
  public close(historyBack = false): void {
    // historyBack 鏄惁闇€瑕佸悗閫€鍘嗗彶绾綍锛岄粯璁や负 `false`銆傝鍙傛暟浠呭唴閮ㄤ娇鐢╘n    // 涓 `false` 鏃舵槸閫氳繃 js 鍏抽棴锛岄渶瑕佸悗閫€涓€涓巻鍙茶褰昞n    // 涓 `true` 鏃舵槸閫氳繃鍚庨€€鎸夐挳鍏抽棴锛屼笉闇€瑕佸悗閫€鍘嗗彶璁板綍

    // setTimeout 鐨勪綔鐢ㄦ槸锛歕n    // 褰撳悓鏃跺叧闂竴涓璇濇锛屽苟鎵撳紑鍙︿竴涓璇濇鏃讹紝浣挎墦寮€瀵硅瘽妗嗙殑鎿嶄綔鍏堟墽琛岋紝浠ヤ娇闇€瑕佹墦寮€鐨勫璇濇鍏堝姞鍏ラ槦鍒梊n    setTimeout(() => {
      if (!this.isOpen()) {
        return;
      }

      currentInst = null;

      this.state = 'closing';
      this.triggerEvent('close');

      // 鎵€鏈夊璇濇閮藉叧闂紝涓斿綋鍓嶆病鏈夋墦寮€鐨勫璇濇鏃讹紝闅愯棌閬僵
      if (!queue(queueName).length && $overlay) {
        $.hideOverlay();
        $overlay = null;

        // 鑻ヤ粛瀛樺湪閬僵锛屾仮澶嶉伄缃╃殑 z-index
        $('.mdui-overlay').css('z-index', 2000);
      }

      this.$element
        .removeClass('mdui-dialog-open')
        .transitionEnd(() => this.transitionEnd());

      if (this.options.history && !queue(queueName).length) {
        if (!historyBack) {
          window.history.back();
        }

        $window.off('hashchange', this.hashchangeEvent);
      }

      // 鍏抽棴鏃у璇濇锛屾墦寮€鏂板璇濇銆俓n      // 鍔犱竴鐐瑰欢杩燂紝浠呬粎涓轰簡瑙嗚鏁堟灉鏇村ソ銆備笉鍔犲欢鏃朵篃涓嶅奖鍝嶅姛鑳絓n      setTimeout(() => {
        dequeue(queueName);
      }, 100);
    });
  }

  /**
   * 鍒囨崲瀵硅瘽妗嗘墦寮€/鍏抽棴鐘舵€乗n   */
  public toggle(): void {
    this.isOpen() ? this.close() : this.open();
  }

  /**
   * 鑾峰彇瀵硅瘽妗嗙姸鎬併€傚叡鍖呭惈鍥涚鐘舵€侊細`opening`銆乣opened`銆乣closing`銆乣closed`
   */
  public getState(): STATE {
    return this.state;
  }

  /**
   * 閿€姣佸璇濇
   */
  public destroy(): void {
    if (this.append) {
      this.$element.remove();
    }

    if (!queue(queueName).length && !currentInst) {
      if ($overlay) {
        $.hideOverlay();
        $overlay = null;
      }

      if (isLockScreen) {
        $.unlockScreen();
        isLockScreen = false;
      }
    }
  }

  /**
   * 瀵硅瘽妗嗗唴瀹瑰彉鍖栨椂锛岄渶瑕佽皟鐢ㄨ鏂规硶鏉ヨ皟鏁村璇濇浣嶇疆鍜屾粴鍔ㄦ潯楂樺害
   */
  public handleUpdate(): void {
    this.readjust();
  }
}

export { currentInst, OPTIONS, Dialog };
