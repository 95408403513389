import $ from 'mdui.jq/es/$';
import mdui from '../../mdui';
import '../../global/mutation';
import '../headroom';

$(() => {
  // 婊氬姩鏃堕殣钘忓簲鐢ㄦ爮
  mdui.mutation('.mdui-appbar-scroll-hide', function () {
    new mdui.Headroom(this);
  });

  // 婊氬姩鏃跺彧闅愯棌搴旂敤鏍忎腑鐨勫伐鍏锋爮
  mdui.mutation('.mdui-appbar-scroll-toolbar-hide', function () {
    new mdui.Headroom(this, {
      pinnedClass: 'mdui-headroom-pinned-toolbar',
      unpinnedClass: 'mdui-headroom-unpinned-toolbar',
    });
  });
});
