import $ from 'mdui.jq/es/$';
import extend from 'mdui.jq/es/functions/extend';
import { JQ } from 'mdui.jq/es/JQ';
import 'mdui.jq/es/methods/addClass';
import 'mdui.jq/es/methods/appendTo';
import 'mdui.jq/es/methods/attr';
import 'mdui.jq/es/methods/css';
import 'mdui.jq/es/methods/first';
import 'mdui.jq/es/methods/hasClass';
import 'mdui.jq/es/methods/height';
import 'mdui.jq/es/methods/html';
import 'mdui.jq/es/methods/offset';
import 'mdui.jq/es/methods/on';
import 'mdui.jq/es/methods/removeClass';
import 'mdui.jq/es/methods/width';
import Selector from 'mdui.jq/es/types/Selector';
import mdui from '../../mdui';
import '../../jq_extends/methods/transformOrigin';
import '../../jq_extends/methods/transitionEnd';
import '../../jq_extends/static/guid';
import { componentEvent } from '../../utils/componentEvent';
import { $window } from '../../utils/dom';
import { isAllow, register, unlockEvent } from '../../utils/touchHandler';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * Tooltip 缁勪欢
     *
     * 璇烽€氳繃 `new mdui.Tooltip()` 璋冪敤
     */
    Tooltip: {
      /**
       * 瀹炰緥鍖 Tooltip 缁勪欢
       * @param selector CSS 閫夋嫨鍣ㄣ€佹垨 DOM 鍏冪礌銆佹垨 JQ 瀵硅薄
       * @param options 閰嶇疆鍙傛暟
       */
      new (
        selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
        options?: OPTIONS,
      ): Tooltip;
    };
  }
}

type POSITION = 'auto' | 'bottom' | 'top' | 'left' | 'right';

type OPTIONS = {
  /**
   * Tooltip 鐨勪綅缃€傚彇鍊艰寖鍥村寘鎷 `auto`銆乣bottom`銆乣top`銆乣left`銆乣right`銆俓n   * 涓 `auto` 鏃讹紝浼氳嚜鍔ㄥ垽鏂綅缃€傞粯璁ゅ湪涓嬫柟銆備紭鍏堢骇涓 `bottom` > `top` > `left` > `right`銆俓n   * 榛樿涓 `auto`
   */
  position?: POSITION;

  /**
   * 寤舵椂瑙﹀彂锛屽崟浣嶆绉掋€傞粯璁や负 `0`锛屽嵆娌℃湁寤舵椂銆俓n   */
  delay?: number;

  /**
   * Tooltip 鐨勫唴瀹筡n   */
  content?: string;
};

type STATE = 'opening' | 'opened' | 'closing' | 'closed';
type EVENT = 'open' | 'opened' | 'close' | 'closed';

const DEFAULT_OPTIONS: OPTIONS = {
  position: 'auto',
  delay: 0,
  content: '',
};

class Tooltip {
  /**
   * 瑙﹀彂 tooltip 鍏冪礌鐨 JQ 瀵硅薄
   */
  public $target: JQ;

  /**
   * tooltip 鍏冪礌鐨 JQ 瀵硅薄
   */
  public $element: JQ;

  /**
   * 閰嶇疆鍙傛暟
   */
  public options: OPTIONS = extend({}, DEFAULT_OPTIONS);

  /**
   * 褰撳墠 tooltip 鐨勭姸鎬乗n   */
  private state: STATE = 'closed';

  /**
   * setTimeout 鐨勮繑鍥炲€糪n   */
  private timeoutId: any = null;

  public constructor(
    selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
    options: OPTIONS = {},
  ) {
    this.$target = $(selector).first();

    extend(this.options, options);

    // 鍒涘缓 Tooltip HTML
    this.$element = $(
      `<div class="mdui-tooltip" id="${$.guid()}">${
        this.options.content
      }</div>`,
    ).appendTo(document.body);

    // 缁戝畾浜嬩欢銆傚厓绱犲浜 disabled 鐘舵€佹椂鏃犳硶瑙﹀彂榧犳爣浜嬩欢锛屼负浜嗙粺涓€锛屾妸 touch 浜嬩欢涔熺鐢╘n    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    this.$target
      .on('touchstart mouseenter', function (event) {
        if (that.isDisabled(this as HTMLElement)) {
          return;
        }

        if (!isAllow(event)) {
          return;
        }

        register(event);

        that.open();
      })
      .on('touchend mouseleave', function (event) {
        if (that.isDisabled(this as HTMLElement)) {
          return;
        }

        if (!isAllow(event)) {
          return;
        }

        that.close();
      })
      .on(unlockEvent, function (event) {
        if (that.isDisabled(this as HTMLElement)) {
          return;
        }

        register(event);
      });
  }

  /**
   * 鍏冪礌鏄惁宸茬鐢╘n   * @param element
   */
  private isDisabled(element: HTMLElement): boolean {
    return (
      (element as HTMLInputElement).disabled ||
      $(element).attr('disabled') !== undefined
    );
  }

  /**
   * 鏄惁鏄闈㈣澶嘰n   */
  private isDesktop(): boolean {
    return $window.width() > 1024;
  }

  /**
   * 璁剧疆 Tooltip 鐨勪綅缃甛n   */
  private setPosition(): void {
    let marginLeft: number;
    let marginTop: number;

    // 瑙﹀彂鐨勫厓绱燶n    const targetProps = this.$target[0].getBoundingClientRect();

    // 瑙﹀彂鐨勫厓绱犲拰 Tooltip 涔嬮棿鐨勮窛绂籠n    const targetMargin = this.isDesktop() ? 14 : 24;

    // Tooltip 鐨勫搴﹀拰楂樺害
    const tooltipWidth = this.$element[0].offsetWidth;
    const tooltipHeight = this.$element[0].offsetHeight;

    // Tooltip 鐨勬柟鍚慭n    let position: POSITION = this.options.position!;

    // 鑷姩鍒ゆ柇浣嶇疆锛屽姞 2px锛屼娇 Tooltip 璺濈绐楀彛杈规鑷冲皯鏈 2px 鐨勯棿璺漒n    if (position === 'auto') {
      if (
        targetProps.top +
          targetProps.height +
          targetMargin +
          tooltipHeight +
          2 <
        $window.height()
      ) {
        position = 'bottom';
      } else if (targetMargin + tooltipHeight + 2 < targetProps.top) {
        position = 'top';
      } else if (targetMargin + tooltipWidth + 2 < targetProps.left) {
        position = 'left';
      } else if (
        targetProps.width + targetMargin + tooltipWidth + 2 <
        $window.width() - targetProps.left
      ) {
        position = 'right';
      } else {
        position = 'bottom';
      }
    }

    // 璁剧疆浣嶇疆
    switch (position) {
      case 'bottom':
        marginLeft = -1 * (tooltipWidth / 2);
        marginTop = targetProps.height / 2 + targetMargin;
        this.$element.transformOrigin('top center');
        break;

      case 'top':
        marginLeft = -1 * (tooltipWidth / 2);
        marginTop =
          -1 * (tooltipHeight + targetProps.height / 2 + targetMargin);
        this.$element.transformOrigin('bottom center');
        break;

      case 'left':
        marginLeft = -1 * (tooltipWidth + targetProps.width / 2 + targetMargin);
        marginTop = -1 * (tooltipHeight / 2);
        this.$element.transformOrigin('center right');
        break;

      case 'right':
        marginLeft = targetProps.width / 2 + targetMargin;
        marginTop = -1 * (tooltipHeight / 2);
        this.$element.transformOrigin('center left');
        break;
    }

    const targetOffset = this.$target.offset();

    this.$element.css({
      top: `${targetOffset.top + targetProps.height / 2}px`,
      left: `${targetOffset.left + targetProps.width / 2}px`,
      'margin-left': `${marginLeft}px`,
      'margin-top': `${marginTop}px`,
    });
  }

  /**
   * 瑙﹀彂缁勪欢浜嬩欢
   * @param name
   */
  private triggerEvent(name: EVENT): void {
    componentEvent(name, 'tooltip', this.$target, this);
  }

  /**
   * 鍔ㄧ敾缁撴潫鍥炶皟
   */
  private transitionEnd(): void {
    if (this.$element.hasClass('mdui-tooltip-open')) {
      this.state = 'opened';
      this.triggerEvent('opened');
    } else {
      this.state = 'closed';
      this.triggerEvent('closed');
    }
  }

  /**
   * 褰撳墠 tooltip 鏄惁涓烘墦寮€鐘舵€乗n   */
  private isOpen(): boolean {
    return this.state === 'opening' || this.state === 'opened';
  }

  /**
   * 鎵ц鎵撳紑 tooltip
   */
  private doOpen(): void {
    this.state = 'opening';
    this.triggerEvent('open');

    this.$element
      .addClass('mdui-tooltip-open')
      .transitionEnd(() => this.transitionEnd());
  }

  /**
   * 鎵撳紑 Tooltip
   * @param options 鍏佽姣忔鎵撳紑鏃惰缃笉鍚岀殑鍙傛暟
   */
  public open(options?: OPTIONS): void {
    if (this.isOpen()) {
      return;
    }

    const oldOptions = extend({}, this.options);

    if (options) {
      extend(this.options, options);
    }

    // tooltip 鐨勫唴瀹规湁鏇存柊
    if (oldOptions.content !== this.options.content) {
      this.$element.html(this.options.content);
    }

    this.setPosition();

    if (this.options.delay) {
      this.timeoutId = setTimeout(() => this.doOpen(), this.options.delay);
    } else {
      this.timeoutId = null;
      this.doOpen();
    }
  }

  /**
   * 鍏抽棴 Tooltip
   */
  public close(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }

    if (!this.isOpen()) {
      return;
    }

    this.state = 'closing';
    this.triggerEvent('close');

    this.$element
      .removeClass('mdui-tooltip-open')
      .transitionEnd(() => this.transitionEnd());
  }

  /**
   * 鍒囨崲 Tooltip 鐨勬墦寮€鐘舵€乗n   */
  public toggle(): void {
    this.isOpen() ? this.close() : this.open();
  }

  /**
   * 鑾峰彇 Tooltip 鐘舵€併€傚叡鍖呭惈鍥涚鐘舵€侊細`opening`銆乣opened`銆乣closing`銆乣closed`
   */
  public getState(): STATE {
    return this.state;
  }
}

mdui.Tooltip = Tooltip;
