/**
 * 鏈€缁堢敓鎴愮殑鍏冪礌缁撴瀯涓猴細
 *  <select class="mdui-select" mdui-select="{position: 'top'}" style="display: none;"> // $native
 *    <option value="1">State 1</option>
 *    <option value="2">State 2</option>
 *    <option value="3" disabled="">State 3</option>
 *  </select>
 *  <div class="mdui-select mdui-select-position-top" style="" id="88dec0e4-d4a2-c6d0-0e7f-1ba4501e0553"> // $element
 *    <span class="mdui-select-selected">State 1</span> // $selected
 *    <div class="mdui-select-menu" style="transform-origin: center 100% 0px;"> // $menu
 *      <div class="mdui-select-menu-item mdui-ripple" selected="">State 1</div> // $items
 *      <div class="mdui-select-menu-item mdui-ripple">State 2</div>
 *      <div class="mdui-select-menu-item mdui-ripple" disabled="">State 3</div>
 *    </div>
 *  </div>
 */

import $ from 'mdui.jq/es/$';
import contains from 'mdui.jq/es/functions/contains';
import extend from 'mdui.jq/es/functions/extend';
import { JQ } from 'mdui.jq/es/JQ';
import 'mdui.jq/es/methods/add';
import 'mdui.jq/es/methods/addClass';
import 'mdui.jq/es/methods/after';
import 'mdui.jq/es/methods/append';
import 'mdui.jq/es/methods/appendTo';
import 'mdui.jq/es/methods/attr';
import 'mdui.jq/es/methods/css';
import 'mdui.jq/es/methods/each';
import 'mdui.jq/es/methods/find';
import 'mdui.jq/es/methods/first';
import 'mdui.jq/es/methods/height';
import 'mdui.jq/es/methods/hide';
import 'mdui.jq/es/methods/index';
import 'mdui.jq/es/methods/innerWidth';
import 'mdui.jq/es/methods/is';
import 'mdui.jq/es/methods/on';
import 'mdui.jq/es/methods/remove';
import 'mdui.jq/es/methods/removeAttr';
import 'mdui.jq/es/methods/removeClass';
import 'mdui.jq/es/methods/show';
import 'mdui.jq/es/methods/text';
import 'mdui.jq/es/methods/trigger';
import 'mdui.jq/es/methods/val';
import Selector from 'mdui.jq/es/types/Selector';
import mdui from '../../mdui';
import '../../jq_extends/methods/transitionEnd';
import '../../jq_extends/static/guid';
import { componentEvent } from '../../utils/componentEvent';
import { $document, $window } from '../../utils/dom';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * 涓嬫媺閫夋嫨缁勪欢
     *
     * 璇烽€氳繃 `new mdui.Select()` 璋冪敤
     */
    Select: {
      /**
       * 瀹炰緥鍖 Select 缁勪欢
       * @param selector CSS 閫夋嫨鍣ㄣ€佹垨 DOM 鍏冪礌銆佹垨 JQ 瀵硅薄
       * @param options 閰嶇疆鍙傛暟
       */
      new (
        selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
        options?: OPTIONS,
      ): Select;
    };
  }
}

type OPTIONS = {
  /**
   * 涓嬫媺妗嗕綅缃細`auto`銆乣top`銆乣bottom`
   */
  position?: 'auto' | 'top' | 'bottom';

  /**
   * 鑿滃崟涓庣獥鍙ｄ笂涓嬭竟妗嗚嚦灏戜繚鎸佸灏戦棿璺漒n   */
  gutter?: number;
};

type STATE = 'closing' | 'closed' | 'opening' | 'opened';
type EVENT = 'open' | 'opened' | 'close' | 'closed';

const DEFAULT_OPTIONS: OPTIONS = {
  position: 'auto',
  gutter: 16,
};

class Select {
  /**
   * 鍘熺敓 `<select>` 鍏冪礌鐨 JQ 瀵硅薄
   */
  public $native: JQ<HTMLSelectElement>;

  /**
   * 鐢熸垚鐨 `<div class="mdui-select">` 鍏冪礌鐨 JQ 瀵硅薄
   */
  public $element: JQ = $();

  /**
   * 閰嶇疆鍙傛暟
   */
  public options: OPTIONS = extend({}, DEFAULT_OPTIONS);

  /**
   * select 鐨 size 灞炴€х殑鍊硷紝鏍规嵁璇ュ€艰缃 select 鐨勯珮搴n   */
  private size = 0;

  /**
   * 鍗犱綅鍏冪礌锛屾樉绀哄凡閫変腑鑿滃崟椤圭殑鏂囨湰
   */
  private $selected: JQ = $();

  /**
   * 鑿滃崟椤圭殑澶栧眰鍏冪礌鐨 JQ 瀵硅薄
   */
  private $menu: JQ = $();

  /**
   * 鑿滃崟椤规暟缁勭殑 JQ 瀵硅薄
   */
  private $items: JQ = $();

  /**
   * 褰撳墠閫変腑鐨勮彍鍗曢」鐨勭储寮曞彿
   */
  private selectedIndex = 0;

  /**
   * 褰撳墠閫変腑鑿滃崟椤圭殑鏂囨湰
   */
  private selectedText = '';

  /**
   * 褰撳墠閫変腑鑿滃崟椤圭殑鍊糪n   */
  private selectedValue = '';

  /**
   * 鍞竴 ID
   */
  private uniqueID: string;

  /**
   * 褰撳墠 select 鐨勭姸鎬乗n   */
  private state: STATE = 'closed';

  public constructor(
    selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
    options: OPTIONS = {},
  ) {
    this.$native = $(selector).first() as JQ<HTMLSelectElement>;
    this.$native.hide();

    extend(this.options, options);

    // 涓哄綋鍓 select 鐢熸垚鍞竴 ID
    this.uniqueID = $.guid();

    // 鐢熸垚 select
    this.handleUpdate();

    // 鐐瑰嚮 select 澶栭潰鍖哄煙鍏抽棴
    $document.on('click touchstart', (event: Event) => {
      const $target = $(event.target as HTMLElement);

      if (
        this.isOpen() &&
        !$target.is(this.$element) &&
        !contains(this.$element[0], $target[0])
      ) {
        this.close();
      }
    });
  }

  /**
   * 璋冩暣鑿滃崟浣嶇疆
   */
  private readjustMenu(): void {
    const windowHeight = $window.height();

    // mdui-select 楂樺害
    const elementHeight = this.$element.height();

    // 鑿滃崟椤归珮搴n    const $itemFirst = this.$items.first();
    const itemHeight = $itemFirst.height();
    const itemMargin = parseInt($itemFirst.css('margin-top'));

    // 鑿滃崟楂樺害
    const menuWidth = this.$element.innerWidth() + 0.01; // 蹇呴』姣旂湡瀹炲搴﹀涓€鐐癸紝涓嶇劧浼氬嚭鐜扮渷鐣ュ彿
    let menuHeight = itemHeight * this.size + itemMargin * 2;

    // mdui-select 鍦ㄧ獥鍙ｄ腑鐨勪綅缃甛n    const elementTop = this.$element[0].getBoundingClientRect().top;

    let transformOriginY: string;
    let menuMarginTop: number;

    if (this.options.position === 'bottom') {
      menuMarginTop = elementHeight;
      transformOriginY = '0px';
    } else if (this.options.position === 'top') {
      menuMarginTop = -menuHeight - 1;
      transformOriginY = '100%';
    } else {
      // 鑿滃崟楂樺害涓嶈兘瓒呰繃绐楀彛楂樺害
      const menuMaxHeight = windowHeight - this.options.gutter! * 2;
      if (menuHeight > menuMaxHeight) {
        menuHeight = menuMaxHeight;
      }

      // 鑿滃崟鐨 margin-top
      menuMarginTop = -(
        itemMargin +
        this.selectedIndex * itemHeight +
        (itemHeight - elementHeight) / 2
      );

      const menuMaxMarginTop = -(
        itemMargin +
        (this.size - 1) * itemHeight +
        (itemHeight - elementHeight) / 2
      );
      if (menuMarginTop < menuMaxMarginTop) {
        menuMarginTop = menuMaxMarginTop;
      }

      // 鑿滃崟涓嶈兘瓒呭嚭绐楀彛
      const menuTop = elementTop + menuMarginTop;
      if (menuTop < this.options.gutter!) {
        // 涓嶈兘瓒呭嚭绐楀彛涓婃柟
        menuMarginTop = -(elementTop - this.options.gutter!);
      } else if (menuTop + menuHeight + this.options.gutter! > windowHeight) {
        // 涓嶈兘瓒呭嚭绐楀彛涓嬫柟
        menuMarginTop = -(
          elementTop +
          menuHeight +
          this.options.gutter! -
          windowHeight
        );
      }

      // transform 鐨 Y 杞村潗鏍嘰n      transformOriginY = `${
        this.selectedIndex * itemHeight + itemHeight / 2 + itemMargin
      }px`;
    }

    // 璁剧疆鏍峰紡
    this.$element.innerWidth(menuWidth);
    this.$menu
      .innerWidth(menuWidth)
      .height(menuHeight)
      .css({
        'margin-top': menuMarginTop + 'px',
        'transform-origin': 'center ' + transformOriginY + ' 0',
      });
  }

  /**
   * select 鏄惁涓烘墦寮€鐘舵€乗n   */
  private isOpen(): boolean {
    return this.state === 'opening' || this.state === 'opened';
  }

  /**
   * 瀵瑰師鐢 select 缁勪欢杩涜浜嗕慨鏀瑰悗锛岄渶瑕佽皟鐢ㄨ鏂规硶
   */
  public handleUpdate(): void {
    if (this.isOpen()) {
      this.close();
    }

    this.selectedValue = this.$native.val() as string;

    // 淇濆瓨鑿滃崟椤规暟鎹殑鏁扮粍
    type typeItemsData = {
      value: string;
      text: string;
      disabled: boolean;
      selected: boolean;
      index: number;
    };
    const itemsData: typeItemsData[] = [];
    this.$items = $();

    // 鐢熸垚 HTML
    this.$native.find('option').each((index, option) => {
      const text = option.textContent || '';
      const value = option.value;
      const disabled = option.disabled;
      const selected = this.selectedValue === value;

      itemsData.push({
        value,
        text,
        disabled,
        selected,
        index,
      });

      if (selected) {
        this.selectedText = text;
        this.selectedIndex = index;
      }

      this.$items = this.$items.add(
        '<div class="mdui-select-menu-item mdui-ripple"' +
          (disabled ? ' disabled' : '') +
          (selected ? ' selected' : '') +
          `>${text}</div>`,
      );
    });

    this.$selected = $(
      `<span class="mdui-select-selected">${this.selectedText}</span>`,
    );

    this.$element = $(
      `<div class="mdui-select mdui-select-position-${this.options.position}" ` +
        `style="${this.$native.attr('style')}" ` +
        `id="${this.uniqueID}"></div>`,
    )
      .show()
      .append(this.$selected);

    this.$menu = $('<div class="mdui-select-menu"></div>')
      .appendTo(this.$element)
      .append(this.$items);

    $(`#${this.uniqueID}`).remove();
    this.$native.after(this.$element);

    // 鏍规嵁 select 鐨 size 灞炴€ц缃珮搴n    this.size = parseInt(this.$native.attr('size') || '0');

    if (this.size <= 0) {
      this.size = this.$items.length;

      if (this.size > 8) {
        this.size = 8;
      }
    }

    // 鐐瑰嚮閫夐」鏃跺叧闂笅鎷夎彍鍗昞n    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    this.$items.on('click', function () {
      if (that.state === 'closing') {
        return;
      }

      const $item = $(this);
      const index = $item.index();
      const data = itemsData[index];

      if (data.disabled) {
        return;
      }

      that.$selected.text(data.text);
      that.$native.val(data.value);
      that.$items.removeAttr('selected');
      $item.attr('selected', '');
      that.selectedIndex = data.index;
      that.selectedValue = data.value;
      that.selectedText = data.text;
      that.$native.trigger('change');
      that.close();
    });

    // 鐐瑰嚮 $element 鏃舵墦寮€涓嬫媺鑿滃崟
    this.$element.on('click', (event: Event) => {
      const $target = $(event.target as HTMLElement);

      // 鍦ㄨ彍鍗曚笂鐐瑰嚮鏃朵笉鎵撳紑
      if (
        $target.is('.mdui-select-menu') ||
        $target.is('.mdui-select-menu-item')
      ) {
        return;
      }

      this.toggle();
    });
  }

  /**
   * 鍔ㄧ敾缁撴潫鐨勫洖璋僜n   */
  private transitionEnd(): void {
    this.$element.removeClass('mdui-select-closing');

    if (this.state === 'opening') {
      this.state = 'opened';
      this.triggerEvent('opened');
      this.$menu.css('overflow-y', 'auto');
    }

    if (this.state === 'closing') {
      this.state = 'closed';
      this.triggerEvent('closed');

      // 鎭㈠鏍峰紡
      this.$element.innerWidth('');
      this.$menu.css({
        'margin-top': '',
        height: '',
        width: '',
      });
    }
  }

  /**
   * 瑙﹀彂缁勪欢浜嬩欢
   * @param name
   */
  private triggerEvent(name: EVENT): void {
    componentEvent(name, 'select', this.$native, this);
  }

  /**
   * 鍒囨崲涓嬫媺鑿滃崟鐨勬墦寮€鐘舵€乗n   */
  public toggle(): void {
    this.isOpen() ? this.close() : this.open();
  }

  /**
   * 鎵撳紑涓嬫媺鑿滃崟
   */
  public open(): void {
    if (this.isOpen()) {
      return;
    }

    this.state = 'opening';
    this.triggerEvent('open');
    this.readjustMenu();
    this.$element.addClass('mdui-select-open');
    this.$menu.transitionEnd(() => this.transitionEnd());
  }

  /**
   * 鍏抽棴涓嬫媺鑿滃崟
   */
  public close(): void {
    if (!this.isOpen()) {
      return;
    }

    this.state = 'closing';
    this.triggerEvent('close');
    this.$menu.css('overflow-y', '');
    this.$element
      .removeClass('mdui-select-open')
      .addClass('mdui-select-closing');
    this.$menu.transitionEnd(() => this.transitionEnd());
  }

  /**
   * 鑾峰彇褰撳墠鑿滃崟鐨勭姸鎬併€傚叡鍖呭惈鍥涚鐘舵€侊細`opening`銆乣opened`銆乣closing`銆乣closed`
   */
  public getState(): STATE {
    return this.state;
  }
}

mdui.Select = Select;
