import $ from 'mdui.jq/es/$';
import 'mdui.jq/es/methods/data';
import 'mdui.jq/es/methods/removeClass';
import 'mdui.jq/es/methods/remove';
import '../methods/transitionEnd';

declare module 'mdui.jq/es/interfaces/JQStatic' {
  interface JQStatic {
    /**
     * 闅愯棌閬僵灞俓n     *
     * 濡傛灉璋冪敤浜嗗娆 $.showOverlay() 鏉ユ樉绀洪伄缃╁眰锛屽垯涔熼渶瑕佽皟鐢ㄧ浉鍚屾鏁扮殑 $.hideOverlay() 鎵嶈兘闅愯棌閬僵灞傘€傚彲浠ラ€氳繃浼犲叆鍙傛暟 true 鏉ュ己鍒堕殣钘忛伄缃╁眰銆俓n     * @param force 鏄惁寮哄埗闅愯棌閬僵
     * @example
```js
$.hideOverlay();
```
     * @example
```js
$.hideOverlay(true);
```
     */
    hideOverlay(force?: boolean): void;
  }
}

$.hideOverlay = function (force = false): void {
  const $overlay = $('.mdui-overlay');

  if (!$overlay.length) {
    return;
  }

  let level = force ? 1 : $overlay.data('_overlay_level');

  if (level > 1) {
    $overlay.data('_overlay_level', --level);
    return;
  }

  $overlay
    .data('_overlay_level', 0)
    .removeClass('mdui-overlay-show')
    .data('_overlay_is_deleted', true)
    .transitionEnd(() => {
      if ($overlay.data('_overlay_is_deleted')) {
        $overlay.remove();
      }
    });
};
