import { MduiStatic } from './interfaces/MduiStatic';
import $ from 'mdui.jq/es/$';
import { $body } from './utils/dom';

// 閬垮厤椤甸潰鍔犺浇瀹屽悗鐩存帴鎵цcss鍔ㄧ敾
// https://css-tricks.com/transitions-only-after-page-load/
setTimeout(() => $body.addClass('mdui-loaded'));

const mdui = {
  $: $,
} as MduiStatic;

export default mdui;
