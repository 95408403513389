import PlainObject from 'mdui.jq/es/interfaces/PlainObject';
import data from 'mdui.jq/es/functions/data';

type TYPE_API_INIT = (
  this: HTMLElement,
  i: number,
  element: HTMLElement,
) => void;

/**
 * CSS 閫夋嫨鍣ㄥ拰鍒濆鍖栧嚱鏁扮粍鎴愮殑瀵硅薄
 */
const entries: PlainObject<TYPE_API_INIT> = {};

/**
 * 娉ㄥ唽骞舵墽琛屽垵濮嬪寲鍑芥暟
 * @param selector CSS 閫夋嫨鍣╘n * @param apiInit 鍒濆鍖栧嚱鏁癨n * @param i 鍏冪礌绱㈠紩
 * @param element 鍏冪礌
 */
function mutation(
  selector: string,
  apiInit: TYPE_API_INIT,
  i: number,
  element: HTMLElement,
): void {
  let selectors = data(element, '_mdui_mutation');

  if (!selectors) {
    selectors = [];
    data(element, '_mdui_mutation', selectors);
  }

  if (selectors.indexOf(selector) === -1) {
    selectors.push(selector);
    apiInit.call(element, i, element);
  }
}

export { TYPE_API_INIT, entries, mutation };
