import $ from 'mdui.jq/es/$';
import { JQ } from 'mdui.jq/es/JQ';
import PlainObject from 'mdui.jq/es/interfaces/PlainObject';
import 'mdui.jq/es/methods/trigger';

/**
 * 瑙﹀彂缁勪欢涓婄殑浜嬩欢
 * @param eventName 浜嬩欢鍚峔n * @param componentName 缁勪欢鍚峔n * @param target 鍦ㄨ鍏冪礌涓婅Е鍙戜簨浠禱n * @param instance 缁勪欢瀹炰緥
 * @param parameters 浜嬩欢鍙傛暟
 */
function componentEvent(
  eventName: string,
  componentName: string,
  target: HTMLElement | HTMLElement[] | JQ,
  instance?: any,
  parameters?: PlainObject,
): void {
  if (!parameters) {
    parameters = {};
  }

  // @ts-ignore
  parameters.inst = instance;

  const fullEventName = `${eventName}.mdui.${componentName}`;

  // jQuery 浜嬩欢
  // @ts-ignore
  if (typeof jQuery !== 'undefined') {
    // @ts-ignore
    jQuery(target).trigger(fullEventName, parameters);
  }

  const $target = $(target);

  // mdui.jq 浜嬩欢
  $target.trigger(fullEventName, parameters);

  // 鍘熺敓浜嬩欢锛屼緵浣跨敤 addEventListener 鐩戝惉
  type EventParams = {
    detail?: any;
    bubbles: boolean;
    cancelable: boolean;
  };

  const eventParams: EventParams = {
    bubbles: true,
    cancelable: true,
    detail: parameters,
  };

  const eventObject: CustomEvent = new CustomEvent(fullEventName, eventParams);

  // @ts-ignore
  eventObject._detail = parameters;

  $target[0].dispatchEvent(eventObject);
}

export { componentEvent };
