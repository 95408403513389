import $ from 'mdui.jq/es/$';
import extend from 'mdui.jq/es/functions/extend';
import { JQ } from 'mdui.jq/es/JQ';
import 'mdui.jq/es/methods/addClass';
import 'mdui.jq/es/methods/appendTo';
import 'mdui.jq/es/methods/attr';
import 'mdui.jq/es/methods/children';
import 'mdui.jq/es/methods/css';
import 'mdui.jq/es/methods/each';
import 'mdui.jq/es/methods/eq';
import 'mdui.jq/es/methods/first';
import 'mdui.jq/es/methods/get';
import 'mdui.jq/es/methods/hasClass';
import 'mdui.jq/es/methods/hide';
import 'mdui.jq/es/methods/index';
import 'mdui.jq/es/methods/innerWidth';
import 'mdui.jq/es/methods/offset';
import 'mdui.jq/es/methods/on';
import 'mdui.jq/es/methods/removeClass';
import 'mdui.jq/es/methods/show';
import Selector from 'mdui.jq/es/types/Selector';
import { isNumber } from 'mdui.jq/es/utils';
import mdui from '../../mdui';
import '../../jq_extends/static/throttle';
import { componentEvent } from '../../utils/componentEvent';
import { $window } from '../../utils/dom';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * Tab 閫夐」鍗＄粍浠禱n     *
     * 璇烽€氳繃 `new mdui.Tab()` 璋冪敤
     */
    Tab: {
      /**
       * 瀹炰緥鍖 Tab 缁勪欢
       * @param selector CSS 閫夋嫨鍣ㄣ€佹垨 DOM 鍏冪礌銆佹垨 JQ 瀵硅薄
       * @param options 閰嶇疆鍙傛暟
       */
      new (
        selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
        options?: OPTIONS,
      ): Tab;
    };
  }
}

type OPTIONS = {
  /**
   * 鍒囨崲閫夐」鍗＄殑瑙﹀彂鏂瑰紡銆俙click`: 鐐瑰嚮鍒囨崲锛沗hover`: 榧犳爣鎮诞鍒囨崲
   */
  trigger?: 'click' | 'hover';

  /**
   * 鏄惁鍚敤寰幆鍒囨崲锛岃嫢涓 `true`锛屽垯鏈€鍚庝竴涓€夐」婵€娲绘椂璋冪敤 `next` 鏂规硶灏嗗洖鍒扮涓€涓€夐」锛岀涓€涓€夐」婵€娲绘椂璋冪敤 `prev` 鏂规硶灏嗗洖鍒版渶鍚庝竴涓€夐」銆俓n   */
  loop?: boolean;
};

type EVENT = 'change' | 'show';

const DEFAULT_OPTIONS: OPTIONS = {
  trigger: 'click',
  loop: false,
};

class Tab {
  /**
   * tab 鍏冪礌鐨 JQ 瀵硅薄
   */
  public $element: JQ;

  /**
   * 閰嶇疆鍙傛暟
   */
  public options: OPTIONS = extend({}, DEFAULT_OPTIONS);

  /**
   * 褰撳墠婵€娲荤殑 tab 鐨勭储寮曞彿銆備负 -1 鏃惰〃绀烘病鏈夋縺娲荤殑閫夐」鍗★紝鎴栦笉瀛樺湪閫夐」鍗n   */
  public activeIndex = -1;

  /**
   * 閫夐」鏁扮粍 JQ 瀵硅薄
   */
  private $tabs: JQ;

  /**
   * 婵€娲荤姸鎬佺殑 tab 搴曢儴鐨勬寚绀虹
   */
  private $indicator: JQ;

  public constructor(
    selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
    options: OPTIONS = {},
  ) {
    this.$element = $(selector).first();

    extend(this.options, options);

    this.$tabs = this.$element.children('a');
    this.$indicator = $('<div class="mdui-tab-indicator"></div>').appendTo(
      this.$element,
    );

    // 鏍规嵁 url hash 鑾峰彇榛樿婵€娲荤殑閫夐」鍗n    const hash = window.location.hash;
    if (hash) {
      this.$tabs.each((index, tab) => {
        if ($(tab).attr('href') === hash) {
          this.activeIndex = index;
          return false;
        }

        return true;
      });
    }

    // 鍚 .mdui-tab-active 鐨勫厓绱犻粯璁ゆ縺娲籠n    if (this.activeIndex === -1) {
      this.$tabs.each((index, tab) => {
        if ($(tab).hasClass('mdui-tab-active')) {
          this.activeIndex = index;
          return false;
        }

        return true;
      });
    }

    // 瀛樺湪閫夐」鍗℃椂锛岄粯璁ゆ縺娲荤涓€涓€夐」鍗n    if (this.$tabs.length && this.activeIndex === -1) {
      this.activeIndex = 0;
    }

    // 璁剧疆婵€娲荤姸鎬侀€夐」鍗n    this.setActive();

    // 鐩戝惉绐楀彛澶у皬鍙樺寲浜嬩欢锛岃皟鏁存寚绀哄櫒浣嶇疆
    $window.on(
      'resize',
      $.throttle(() => this.setIndicatorPosition(), 100),
    );

    // 鐩戝惉鐐瑰嚮閫夐」鍗′簨浠禱n    this.$tabs.each((_, tab) => {
      this.bindTabEvent(tab);
    });
  }

  /**
   * 鎸囧畾閫夐」鍗℃槸鍚﹀凡绂佺敤
   * @param $tab
   */
  private isDisabled($tab: JQ): boolean {
    return $tab.attr('disabled') !== undefined;
  }

  /**
   * 缁戝畾鍦 Tab 涓婄偣鍑绘垨鎮诞鐨勪簨浠禱n   * @param tab
   */
  private bindTabEvent(tab: HTMLElement): void {
    const $tab = $(tab);

    // 鐐瑰嚮鎴栭紶鏍囩Щ鍏ヨЕ鍙戠殑浜嬩欢
    const clickEvent = (): void | false => {
      // 绂佺敤鐘舵€佺殑閫夐」鍗℃棤娉曢€変腑
      if (this.isDisabled($tab)) {
        return false;
      }

      this.activeIndex = this.$tabs.index(tab);
      this.setActive();
    };

    // 鏃犺 trigger 鏄 click 杩樻槸 hover锛岄兘浼氬搷搴 click 浜嬩欢
    $tab.on('click', clickEvent);

    // trigger 涓 hover 鏃讹紝棰濆鍝嶅簲 mouseenter 浜嬩欢
    if (this.options.trigger === 'hover') {
      $tab.on('mouseenter', clickEvent);
    }

    // 闃绘閾炬帴鐨勯粯璁ょ偣鍑诲姩浣淺n    $tab.on('click', (): void | false => {
      if (($tab.attr('href') || '').indexOf('#') === 0) {
        return false;
      }
    });
  }

  /**
   * 瑙﹀彂缁勪欢浜嬩欢
   * @param name
   * @param $element
   * @param parameters
   */
  private triggerEvent(name: EVENT, $element: JQ, parameters = {}): void {
    componentEvent(name, 'tab', $element, this, parameters);
  }

  /**
   * 璁剧疆婵€娲荤姸鎬佺殑閫夐」鍗n   */
  private setActive(): void {
    this.$tabs.each((index, tab) => {
      const $tab = $(tab);
      const targetId = $tab.attr('href') || '';

      // 璁剧疆閫夐」鍗℃縺娲荤姸鎬乗n      if (index === this.activeIndex && !this.isDisabled($tab)) {
        if (!$tab.hasClass('mdui-tab-active')) {
          this.triggerEvent('change', this.$element, {
            index: this.activeIndex,
            id: targetId.substr(1),
          });
          this.triggerEvent('show', $tab);

          $tab.addClass('mdui-tab-active');
        }

        $(targetId).show();
        this.setIndicatorPosition();
      } else {
        $tab.removeClass('mdui-tab-active');
        $(targetId).hide();
      }
    });
  }

  /**
   * 璁剧疆閫夐」鍗℃寚绀哄櫒鐨勪綅缃甛n   */
  private setIndicatorPosition(): void {
    // 閫夐」鍗℃暟閲忎负 0 鏃讹紝涓嶆樉绀烘寚绀哄櫒
    if (this.activeIndex === -1) {
      this.$indicator.css({
        left: 0,
        width: 0,
      });

      return;
    }

    const $activeTab = this.$tabs.eq(this.activeIndex);

    if (this.isDisabled($activeTab)) {
      return;
    }

    const activeTabOffset = $activeTab.offset();

    this.$indicator.css({
      left: `${
        activeTabOffset.left +
        this.$element[0].scrollLeft -
        this.$element[0].getBoundingClientRect().left
      }px`,
      width: `${$activeTab.innerWidth()}px`,
    });
  }

  /**
   * 鍒囨崲鍒颁笅涓€涓€夐」鍗n   */
  public next(): void {
    if (this.activeIndex === -1) {
      return;
    }

    if (this.$tabs.length > this.activeIndex + 1) {
      this.activeIndex++;
    } else if (this.options.loop) {
      this.activeIndex = 0;
    }

    this.setActive();
  }

  /**
   * 鍒囨崲鍒颁笂涓€涓€夐」鍗n   */
  public prev(): void {
    if (this.activeIndex === -1) {
      return;
    }

    if (this.activeIndex > 0) {
      this.activeIndex--;
    } else if (this.options.loop) {
      this.activeIndex = this.$tabs.length - 1;
    }

    this.setActive();
  }

  /**
   * 鏄剧ず鎸囧畾绱㈠紩鍙枫€佹垨鎸囧畾id鐨勯€夐」鍗n   * @param index 绱㈠紩鍙枫€佹垨id
   */
  public show(index: number | string): void {
    if (this.activeIndex === -1) {
      return;
    }

    if (isNumber(index)) {
      this.activeIndex = index;
    } else {
      this.$tabs.each((i, tab): void | false => {
        if (tab.id === index) {
          this.activeIndex === i;
          return false;
        }
      });
    }

    this.setActive();
  }

  /**
   * 鍦ㄧ埗鍏冪礌鐨勫搴﹀彉鍖栨椂锛岄渶瑕佽皟鐢ㄨ鏂规硶閲嶆柊璋冩暣鎸囩ず鍣ㄤ綅缃甛n   * 鍦ㄦ坊鍔犳垨鍒犻櫎閫夐」鍗℃椂锛岄渶瑕佽皟鐢ㄨ鏂规硶
   */
  public handleUpdate(): void {
    const $oldTabs = this.$tabs; // 鏃х殑 tabs JQ瀵硅薄
    const $newTabs = this.$element.children('a'); // 鏂扮殑 tabs JQ瀵硅薄
    const oldTabsElement = $oldTabs.get(); // 鏃х殑 tabs 鍏冪礌鏁扮粍
    const newTabsElement = $newTabs.get(); // 鏂扮殑 tabs 鍏冪礌鏁扮粍

    if (!$newTabs.length) {
      this.activeIndex = -1;
      this.$tabs = $newTabs;
      this.setIndicatorPosition();

      return;
    }

    // 閲嶆柊閬嶅巻閫夐」鍗★紝鎵惧嚭鏂板鐨勯€夐」鍗n    $newTabs.each((index, tab) => {
      // 鏈夋柊澧炵殑閫夐」鍗n      if (oldTabsElement.indexOf(tab) < 0) {
        this.bindTabEvent(tab);

        if (this.activeIndex === -1) {
          this.activeIndex = 0;
        } else if (index <= this.activeIndex) {
          this.activeIndex++;
        }
      }
    });

    // 鎵惧嚭琚Щ闄ょ殑閫夐」鍗n    $oldTabs.each((index, tab) => {
      // 鏈夎绉婚櫎鐨勯€夐」鍗n      if (newTabsElement.indexOf(tab) < 0) {
        if (index < this.activeIndex) {
          this.activeIndex--;
        } else if (index === this.activeIndex) {
          this.activeIndex = 0;
        }
      }
    });

    this.$tabs = $newTabs;

    this.setActive();
  }
}

mdui.Tab = Tab;
