import $ from 'mdui.jq/es/$';
import each from 'mdui.jq/es/functions/each';
import extend from 'mdui.jq/es/functions/extend';
import 'mdui.jq/es/methods/each';
import 'mdui.jq/es/methods/find';
import 'mdui.jq/es/methods/on';
import mdui from '../../mdui';
import { Dialog } from './class';
import './index';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * 鎵撳紑涓€涓璇濇锛屾爣棰樸€佸唴瀹广€佹寜閽瓑閮藉彲浠ヨ嚜瀹氫箟
     * @param options 閰嶇疆鍙傛暟
     */
    dialog(options: OPTIONS): Dialog;
  }
}

type BUTTON = {
  /**
   * 鎸夐挳鏂囨湰
   */
  text?: string;

  /**
   * 鎸夐挳鏂囨湰鏄惁鍔犵矖锛岄粯璁や负 `false`
   */
  bold?: boolean;

  /**
   * 鐐瑰嚮鎸夐挳鍚庢槸鍚﹀叧闂璇濇锛岄粯璁や负 `true`
   */
  close?: boolean;

  /**
   * 鐐瑰嚮鎸夐挳鐨勫洖璋冨嚱鏁帮紝鍙傛暟涓哄璇濇鐨勫疄渚媆n   */
  onClick?: (dialog: Dialog) => void;
};

type OPTIONS = {
  /**
   * 瀵硅瘽妗嗙殑鏍囬
   */
  title?: string;

  /**
   * 瀵硅瘽妗嗙殑鍐呭
   */
  content?: string;

  /**
   * 鎸夐挳鏁扮粍锛屾瘡涓寜閽兘鏄竴涓甫鎸夐挳鍙傛暟鐨勫璞n   */
  buttons?: BUTTON[];

  /**
   * 鎸夐挳鏄惁鍨傜洿鎺掑垪锛岄粯璁や负 `false`
   */
  stackedButtons?: boolean;

  /**
   * 娣诲姞鍒 `.mdui-dialog` 涓婄殑 CSS 绫籠n   */
  cssClass?: string;

  /**
   * 鏄惁鐩戝惉 `hashchange` 浜嬩欢锛屼负 `true` 鏃跺彲浠ラ€氳繃 Android 鐨勮繑鍥為敭鎴栨祻瑙堝櫒鍚庨€€鎸夐挳鍏抽棴瀵硅瘽妗嗭紝榛樿涓 `true`
   */
  history?: boolean;

  /**
   * 鎵撳紑瀵硅瘽妗嗗悗鏄惁鏄剧ず閬僵灞傦紝榛樿涓 `true`
   */
  overlay?: boolean;

  /**
   * 鏄惁妯℃€佸寲瀵硅瘽妗嗐€備负 `false` 鏃剁偣鍑诲璇濇澶栭潰鐨勫尯鍩熸椂鍏抽棴瀵硅瘽妗嗭紝鍚﹀垯涓嶅叧闂璡n   */
  modal?: boolean;

  /**
   * 鎸変笅 Esc 閿椂鏄惁鍏抽棴瀵硅瘽妗嗭紝榛樿涓 `true`
   */
  closeOnEsc?: boolean;

  /**
   * 鍏抽棴瀵硅瘽妗嗗悗鏄惁鑷姩閿€姣佸璇濇锛岄粯璁や负 `true`
   */
  destroyOnClosed?: boolean;

  /**
   * 鎵撳紑鍔ㄧ敾寮€濮嬫椂鐨勫洖璋冿紝鍙傛暟涓哄璇濇瀹炰緥
   */
  onOpen?: (dialog: Dialog) => void;

  /**
   * 鎵撳紑鍔ㄧ敾缁撴潫鏃剁殑鍥炶皟锛屽弬鏁颁负瀵硅瘽妗嗗疄渚媆n   */
  onOpened?: (dialog: Dialog) => void;

  /**
   * 鍏抽棴鍔ㄧ敾寮€濮嬫椂鐨勫洖璋冿紝鍙傛暟涓哄璇濇瀹炰緥
   */
  onClose?: (dialog: Dialog) => void;

  /**
   * 鍏抽棴鍔ㄧ敾缁撴潫鏃剁殑鍥炶皟锛屽弬鏁颁负瀵硅瘽妗嗗疄渚媆n   */
  onClosed?: (dialog: Dialog) => void;
};

const DEFAULT_BUTTON: BUTTON = {
  text: '',
  bold: false,
  close: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick: () => {},
};

const DEFAULT_OPTIONS: OPTIONS = {
  title: '',
  content: '',
  buttons: [],
  stackedButtons: false,
  cssClass: '',
  history: true,
  overlay: true,
  modal: false,
  closeOnEsc: true,
  destroyOnClosed: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onOpen: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onOpened: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClosed: () => {},
};

mdui.dialog = function (options: OPTIONS): Dialog {
  // 鍚堝苟閰嶇疆鍙傛暟
  options = extend({}, DEFAULT_OPTIONS, options);

  each(options.buttons!, (i, button) => {
    options.buttons![i] = extend({}, DEFAULT_BUTTON, button);
  });

  // 鎸夐挳鐨 HTML
  let buttonsHTML = '';
  if (options.buttons?.length) {
    buttonsHTML = `<div class="mdui-dialog-actions${
      options.stackedButtons ? ' mdui-dialog-actions-stacked' : ''
    }">`;

    each(options.buttons, (_, button) => {
      buttonsHTML +=
        '<a href="javascript:void(0)" ' +
        `class="mdui-btn mdui-ripple mdui-text-color-primary ${
          button.bold ? 'mdui-btn-bold' : ''
        }">${button.text}</a>`;
    });

    buttonsHTML += '</div>';
  }

  // Dialog 鐨 HTML
  const HTML =
    `<div class="mdui-dialog ${options.cssClass}">` +
    (options.title
      ? `<div class="mdui-dialog-title">${options.title}</div>`
      : '') +
    (options.content
      ? `<div class="mdui-dialog-content">${options.content}</div>`
      : '') +
    buttonsHTML +
    '</div>';

  // 瀹炰緥鍖 Dialog
  const instance = new mdui.Dialog(HTML, {
    history: options.history,
    overlay: options.overlay,
    modal: options.modal,
    closeOnEsc: options.closeOnEsc,
    destroyOnClosed: options.destroyOnClosed,
  });

  // 缁戝畾鎸夐挳浜嬩欢
  if (options.buttons?.length) {
    instance.$element
      .find('.mdui-dialog-actions .mdui-btn')
      .each((index, button) => {
        $(button).on('click', () => {
          options.buttons![index].onClick!(instance);

          if (options.buttons![index].close) {
            instance.close();
          }
        });
      });
  }

  // 缁戝畾鎵撳紑鍏抽棴浜嬩欢
  instance.$element
    .on('open.mdui.dialog', () => {
      options.onOpen!(instance);
    })
    .on('opened.mdui.dialog', () => {
      options.onOpened!(instance);
    })
    .on('close.mdui.dialog', () => {
      options.onClose!(instance);
    })
    .on('closed.mdui.dialog', () => {
      options.onClosed!(instance);
    });

  instance.open();

  return instance;
};
