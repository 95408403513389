import $ from 'mdui.jq/es/$';
import extend from 'mdui.jq/es/functions/extend';
import { JQ } from 'mdui.jq/es/JQ';
import 'mdui.jq/es/methods/addClass';
import 'mdui.jq/es/methods/children';
import 'mdui.jq/es/methods/each';
import 'mdui.jq/es/methods/eq';
import 'mdui.jq/es/methods/first';
import 'mdui.jq/es/methods/hasClass';
import 'mdui.jq/es/methods/height';
import 'mdui.jq/es/methods/is';
import 'mdui.jq/es/methods/on';
import 'mdui.jq/es/methods/parent';
import 'mdui.jq/es/methods/parents';
import 'mdui.jq/es/methods/removeClass';
import Selector from 'mdui.jq/es/types/Selector';
import { isNumber } from 'mdui.jq/es/utils';
import '../../jq_extends/methods/reflow';
import '../../jq_extends/methods/transition';
import '../../jq_extends/methods/transitionEnd';
import { componentEvent } from '../../utils/componentEvent';

type OPTIONS = {
  /**
   * 鏄惁鍚敤鎵嬮鐞存晥鏋淺n   * 涓 `true` 鏃讹紝鏈€澶氬彧鑳芥湁涓€涓潰鏉块」澶勪簬鎵撳紑鐘舵€侊紝鎵撳紑涓€涓潰鏉块」鏃朵細鍏抽棴鍏朵粬闈㈡澘椤筡n   * 涓 `false` 鏃讹紝鍙悓鏃舵墦寮€澶氫釜闈㈡澘椤筡n   */
  accordion?: boolean;
};

type EVENT = 'open' | 'opened' | 'close' | 'closed';

const DEFAULT_OPTIONS: OPTIONS = {
  accordion: false,
};

abstract class CollapseAbstract {
  /**
   * collapse 鍏冪礌鐨 JQ 瀵硅薄
   */
  public $element: JQ;

  /**
   * 閰嶇疆鍙傛暟
   */
  public options: OPTIONS = extend({}, DEFAULT_OPTIONS);

  /**
   * item 鐨 class 鍚峔n   */
  private classItem: string;

  /**
   * 鎵撳紑鐘舵€佺殑 item 鐨 class 鍚峔n   */
  private classItemOpen: string;

  /**
   * item-header 鐨 class 鍚峔n   */
  private classHeader: string;

  /**
   * item-body 鐨 class 鍚峔n   */
  private classBody: string;

  /**
   * 鑾峰彇缁ф壙鐨勭粍浠跺悕绉癨n   */
  protected abstract getNamespace(): string;

  public constructor(
    selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
    options: OPTIONS = {},
  ) {
    // CSS 绫诲悕
    const classPrefix = `mdui-${this.getNamespace()}-item`;
    this.classItem = classPrefix;
    this.classItemOpen = `${classPrefix}-open`;
    this.classHeader = `${classPrefix}-header`;
    this.classBody = `${classPrefix}-body`;

    this.$element = $(selector).first();

    extend(this.options, options);

    this.bindEvent();
  }

  /**
   * 缁戝畾浜嬩欢
   */
  private bindEvent(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    const $items = this.getItems();

    // 鐐瑰嚮 header 鏃讹紝鎵撳紑/鍏抽棴 item
    this.$element.on('click', `.${this.classHeader}`, function () {
      const $header = $(this as HTMLElement);
      const $item = $header.parent();

      $items.each((_, item) => {
        if ($item.is(item)) {
          that.toggle(item);
        }
      });
    });

    // 鐐瑰嚮鍏抽棴鎸夐挳鏃讹紝鍏抽棴 item
    this.$element.on(
      'click',
      `[mdui-${this.getNamespace()}-item-close]`,
      function () {
        const $target = $(this as HTMLElement);
        const $item = $target.parents(`.${that.classItem}`).first();

        that.close($item);
      },
    );
  }

  /**
   * 鎸囧畾 item 鏄惁澶勪簬鎵撳紑鐘舵€乗n   * @param $item
   */
  private isOpen($item: JQ): boolean {
    return $item.hasClass(this.classItemOpen);
  }

  /**
   * 鑾峰彇鎵€鏈 item
   */
  private getItems(): JQ {
    return this.$element.children(`.${this.classItem}`);
  }

  /**
   * 鑾峰彇鎸囧畾 item
   * @param item
   */
  private getItem(
    item: number | Selector | HTMLElement | ArrayLike<HTMLElement>,
  ): JQ {
    if (isNumber(item)) {
      return this.getItems().eq(item);
    }

    return $(item).first();
  }

  /**
   * 瑙﹀彂缁勪欢浜嬩欢
   * @param name 浜嬩欢鍚峔n   * @param $item 浜嬩欢瑙﹀彂鐨勭洰鏍 item
   */
  private triggerEvent(name: EVENT, $item: JQ): void {
    componentEvent(name, this.getNamespace(), $item, this);
  }

  /**
   * 鍔ㄧ敾缁撴潫鍥炶皟
   * @param $content body 鍏冪礌
   * @param $item item 鍏冪礌
   */
  private transitionEnd($content: JQ, $item: JQ): void {
    if (this.isOpen($item)) {
      $content.transition(0).height('auto').reflow().transition('');

      this.triggerEvent('opened', $item);
    } else {
      $content.height('');

      this.triggerEvent('closed', $item);
    }
  }

  /**
   * 鎵撳紑鎸囧畾闈㈡澘椤筡n   * @param item 闈㈡澘椤圭殑绱㈠紩鍙枫€佹垨 CSS 閫夋嫨鍣ㄣ€佹垨 DOM 鍏冪礌銆佹垨 JQ 瀵硅薄
   */
  public open(
    item: number | Selector | HTMLElement | ArrayLike<HTMLElement>,
  ): void {
    const $item = this.getItem(item);

    if (this.isOpen($item)) {
      return;
    }

    // 鍏抽棴鍏朵粬椤筡n    if (this.options.accordion) {
      this.$element.children(`.${this.classItemOpen}`).each((_, element) => {
        const $element = $(element);

        if (!$element.is($item)) {
          this.close($element);
        }
      });
    }

    const $content = $item.children(`.${this.classBody}`);

    $content
      .height($content[0].scrollHeight)
      .transitionEnd(() => this.transitionEnd($content, $item));

    this.triggerEvent('open', $item);

    $item.addClass(this.classItemOpen);
  }

  /**
   * 鍏抽棴鎸囧畾闈㈡澘椤筡n   * @param item 闈㈡澘椤圭殑绱㈠紩鍙枫€佹垨 CSS 閫夋嫨鍣ㄣ€佹垨 DOM 鍏冪礌銆佹垨 JQ 瀵硅薄
   */
  public close(
    item: number | Selector | HTMLElement | ArrayLike<HTMLElement>,
  ): void {
    const $item = this.getItem(item);

    if (!this.isOpen($item)) {
      return;
    }

    const $content = $item.children(`.${this.classBody}`);

    this.triggerEvent('close', $item);

    $item.removeClass(this.classItemOpen);

    $content
      .transition(0)
      .height($content[0].scrollHeight)
      .reflow()
      .transition('')
      .height('')
      .transitionEnd(() => this.transitionEnd($content, $item));
  }

  /**
   * 鍒囨崲鎸囧畾闈㈡澘椤圭殑鎵撳紑鐘舵€乗n   * @param item 闈㈡澘椤圭殑绱㈠紩鍙枫€佹垨 CSS 閫夋嫨鍣ㄣ€佹垨 DOM 鍏冪礌銆佹垨 JQ 瀵硅薄
   */
  public toggle(
    item: number | Selector | HTMLElement | ArrayLike<HTMLElement>,
  ): void {
    const $item = this.getItem(item);

    this.isOpen($item) ? this.close($item) : this.open($item);
  }

  /**
   * 鎵撳紑鎵€鏈夐潰鏉块」
   */
  public openAll(): void {
    this.getItems().each((_, element) => this.open(element));
  }

  /**
   * 鍏抽棴鎵€鏈夐潰鏉块」
   */
  public closeAll(): void {
    this.getItems().each((_, element) => this.close(element));
  }
}

export { OPTIONS, CollapseAbstract };
