/**
 * 鍦ㄦ闈㈣澶囦笂榛樿鏄剧ず鎶藉眽鏍忥紝涓嶆樉绀洪伄缃╁眰
 * 鍦ㄦ墜鏈哄拰骞虫澘璁惧涓婇粯璁や笉鏄剧ず鎶藉眽鏍忥紝濮嬬粓鏄剧ず閬僵灞傦紝涓旇鐩栧鑸爮
 */

import $ from 'mdui.jq/es/$';
import extend from 'mdui.jq/es/functions/extend';
import { JQ } from 'mdui.jq/es/JQ';
import 'mdui.jq/es/methods/addClass';
import 'mdui.jq/es/methods/each';
import 'mdui.jq/es/methods/find';
import 'mdui.jq/es/methods/first';
import 'mdui.jq/es/methods/hasClass';
import 'mdui.jq/es/methods/off';
import 'mdui.jq/es/methods/on';
import 'mdui.jq/es/methods/one';
import 'mdui.jq/es/methods/removeClass';
import 'mdui.jq/es/methods/width';
import Selector from 'mdui.jq/es/types/Selector';
import mdui from '../../mdui';
import '../../jq_extends/methods/transitionEnd';
import '../../jq_extends/static/hideOverlay';
import '../../jq_extends/static/lockScreen';
import '../../jq_extends/static/showOverlay';
import '../../jq_extends/static/throttle';
import '../../jq_extends/static/unlockScreen';
import { componentEvent } from '../../utils/componentEvent';
import { $body, $window } from '../../utils/dom';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * Drawer 缁勪欢
     *
     * 璇烽€氳繃 `new mdui.Drawer()` 璋冪敤
     */
    Drawer: {
      /**
       * 瀹炰緥鍖 Drawer 缁勪欢
       * @param selector CSS 閫夋嫨鍣ㄣ€佹垨 DOM 鍏冪礌銆佹垨 JQ 瀵硅薄
       * @param options 閰嶇疆鍙傛暟
       */
      new (
        selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
        options?: OPTIONS,
      ): Drawer;
    };
  }
}

type OPTIONS = {
  /**
   * 鎵撳紑鎶藉眽鏍忔椂鏄惁鏄剧ず閬僵灞傘€傝鍙傛暟鍙涓瓑灞忓箷鍙婁互涓婄殑璁惧鏈夋晥锛屽湪瓒呭皬灞忓拰灏忓睆璁惧涓婂缁堜細鏄剧ず閬僵灞傘€俓n   */
  overlay?: boolean;

  /**
   * 鏄惁鍚敤婊戝姩鎵嬪娍銆俓n   */
  swipe?: boolean;
};

type STATE = 'opening' | 'opened' | 'closing' | 'closed';
type EVENT = 'open' | 'opened' | 'close' | 'closed';

const DEFAULT_OPTIONS: OPTIONS = {
  overlay: false,
  swipe: false,
};

class Drawer {
  /**
   * drawer 鍏冪礌鐨 JQ 瀵硅薄
   */
  public $element: JQ;

  /**
   * 閰嶇疆鍙傛暟
   */
  public options: OPTIONS = extend({}, DEFAULT_OPTIONS);

  /**
   * 褰撳墠鏄惁鏄剧ず鐫€閬僵灞俓n   */
  private overlay = false;

  /**
   * 鎶藉眽鏍忕殑浣嶇疆
   */
  private position: 'left' | 'right';

  /**
   * 褰撳墠鎶藉眽鏍忕姸鎬乗n   */
  private state: STATE;

  public constructor(
    selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
    options: OPTIONS = {},
  ) {
    this.$element = $(selector).first();

    extend(this.options, options);

    this.position = this.$element.hasClass('mdui-drawer-right')
      ? 'right'
      : 'left';

    if (this.$element.hasClass('mdui-drawer-close')) {
      this.state = 'closed';
    } else if (this.$element.hasClass('mdui-drawer-open')) {
      this.state = 'opened';
    } else if (this.isDesktop()) {
      this.state = 'opened';
    } else {
      this.state = 'closed';
    }

    // 娴忚鍣ㄧ獥鍙ｅぇ灏忚皟鏁存椂
    $window.on(
      'resize',
      $.throttle(() => {
        if (this.isDesktop()) {
          // 鐢辨墜鏈哄钩鏉垮垏鎹㈠埌妗岄潰鏃禱n          // 濡傛灉鏄剧ず鐫€閬僵锛屽垯闅愯棌閬僵
          if (this.overlay && !this.options.overlay) {
            $.hideOverlay();
            this.overlay = false;
            $.unlockScreen();
          }

          // 娌℃湁寮哄埗鍏抽棴锛屽垯鐘舵€佷负鎵撳紑鐘舵€乗n          if (!this.$element.hasClass('mdui-drawer-close')) {
            this.state = 'opened';
          }
        } else if (!this.overlay && this.state === 'opened') {
          // 鐢辨闈㈠垏鎹㈠埌鎵嬫満骞虫澘鏃躲€傚鏋滄娊灞夋爮鏄墦寮€鐫€鐨勪笖娌℃湁閬僵灞傦紝鍒欏叧闂娊灞夋爮
          if (this.$element.hasClass('mdui-drawer-open')) {
            $.showOverlay();
            this.overlay = true;
            $.lockScreen();

            $('.mdui-overlay').one('click', () => this.close());
          } else {
            this.state = 'closed';
          }
        }
      }, 100),
    );

    // 缁戝畾鍏抽棴鎸夐挳浜嬩欢
    this.$element.find('[mdui-drawer-close]').each((_, close) => {
      $(close).on('click', () => this.close());
    });

    this.swipeSupport();
  }

  /**
   * 鏄惁鏄闈㈣澶嘰n   */
  private isDesktop(): boolean {
    return $window.width() >= 1024;
  }

  /**
   * 婊戝姩鎵嬪娍鏀寔
   */
  private swipeSupport(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    // 鎶藉眽鏍忔粦鍔ㄦ墜鍔挎帶鍒禱n    let openNavEventHandler: (event: Event) => void;
    let touchStartX: number;
    let touchStartY: number;
    let swipeStartX: number;
    let swiping: null | 'opening' | 'closing' = null;
    let maybeSwiping = false;

    // 鎵嬪娍瑙﹀彂鐨勮寖鍥碶n    const swipeAreaWidth = 24;

    function setPosition(translateX: number): void {
      const rtlTranslateMultiplier = that.position === 'right' ? -1 : 1;
      const transformCSS = `translate(${
        -1 * rtlTranslateMultiplier * translateX
      }px, 0) !important;`;
      const transitionCSS = 'initial !important;';

      that.$element.css(
        'cssText',
        `transform: ${transformCSS}; transition: ${transitionCSS};`,
      );
    }

    function cleanPosition(): void {
      that.$element[0].style.transform = '';
      that.$element[0].style.webkitTransform = '';
      that.$element[0].style.transition = '';
      that.$element[0].style.webkitTransition = '';
    }

    function getMaxTranslateX(): number {
      return that.$element.width() + 10;
    }

    function getTranslateX(currentX: number): number {
      return Math.min(
        Math.max(
          swiping === 'closing'
            ? swipeStartX - currentX
            : getMaxTranslateX() + swipeStartX - currentX,
          0,
        ),
        getMaxTranslateX(),
      );
    }

    function onBodyTouchEnd(event?: Event): void {
      if (swiping) {
        let touchX = (event as TouchEvent).changedTouches[0].pageX;
        if (that.position === 'right') {
          touchX = $body.width() - touchX;
        }

        const translateRatio = getTranslateX(touchX) / getMaxTranslateX();

        maybeSwiping = false;
        const swipingState = swiping;
        swiping = null;

        if (swipingState === 'opening') {
          if (translateRatio < 0.92) {
            cleanPosition();
            that.open();
          } else {
            cleanPosition();
          }
        } else {
          if (translateRatio > 0.08) {
            cleanPosition();
            that.close();
          } else {
            cleanPosition();
          }
        }

        $.unlockScreen();
      } else {
        maybeSwiping = false;
      }

      $body.off({
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        touchmove: onBodyTouchMove,
        touchend: onBodyTouchEnd,
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        touchcancel: onBodyTouchMove,
      });
    }

    function onBodyTouchMove(event: Event): void {
      let touchX = (event as TouchEvent).touches[0].pageX;
      if (that.position === 'right') {
        touchX = $body.width() - touchX;
      }

      const touchY = (event as TouchEvent).touches[0].pageY;

      if (swiping) {
        setPosition(getTranslateX(touchX));
      } else if (maybeSwiping) {
        const dXAbs = Math.abs(touchX - touchStartX);
        const dYAbs = Math.abs(touchY - touchStartY);
        const threshold = 8;

        if (dXAbs > threshold && dYAbs <= threshold) {
          swipeStartX = touchX;
          swiping = that.state === 'opened' ? 'closing' : 'opening';
          $.lockScreen();
          setPosition(getTranslateX(touchX));
        } else if (dXAbs <= threshold && dYAbs > threshold) {
          onBodyTouchEnd();
        }
      }
    }

    function onBodyTouchStart(event: Event): void {
      touchStartX = (event as TouchEvent).touches[0].pageX;
      if (that.position === 'right') {
        touchStartX = $body.width() - touchStartX;
      }

      touchStartY = (event as TouchEvent).touches[0].pageY;

      if (that.state !== 'opened') {
        if (
          touchStartX > swipeAreaWidth ||
          openNavEventHandler !== onBodyTouchStart
        ) {
          return;
        }
      }

      maybeSwiping = true;

      $body.on({
        touchmove: onBodyTouchMove,
        touchend: onBodyTouchEnd,
        touchcancel: onBodyTouchMove,
      });
    }

    function enableSwipeHandling(): void {
      if (!openNavEventHandler) {
        $body.on('touchstart', onBodyTouchStart);
        openNavEventHandler = onBodyTouchStart;
      }
    }

    if (this.options.swipe) {
      enableSwipeHandling();
    }
  }

  /**
   * 瑙﹀彂缁勪欢浜嬩欢
   * @param name
   */
  private triggerEvent(name: EVENT): void {
    componentEvent(name, 'drawer', this.$element, this);
  }

  /**
   * 鍔ㄧ敾缁撴潫鍥炶皟
   */
  private transitionEnd(): void {
    if (this.$element.hasClass('mdui-drawer-open')) {
      this.state = 'opened';
      this.triggerEvent('opened');
    } else {
      this.state = 'closed';
      this.triggerEvent('closed');
    }
  }

  /**
   * 鏄惁澶勪簬鎵撳紑鐘舵€乗n   */
  private isOpen(): boolean {
    return this.state === 'opening' || this.state === 'opened';
  }

  /**
   * 鎵撳紑鎶藉眽鏍廫n   */
  public open(): void {
    if (this.isOpen()) {
      return;
    }

    this.state = 'opening';
    this.triggerEvent('open');

    if (!this.options.overlay) {
      $body.addClass(`mdui-drawer-body-${this.position}`);
    }

    this.$element
      .removeClass('mdui-drawer-close')
      .addClass('mdui-drawer-open')
      .transitionEnd(() => this.transitionEnd());

    if (!this.isDesktop() || this.options.overlay) {
      this.overlay = true;
      $.showOverlay().one('click', () => this.close());
      $.lockScreen();
    }
  }

  /**
   * 鍏抽棴鎶藉眽鏍廫n   */
  public close(): void {
    if (!this.isOpen()) {
      return;
    }

    this.state = 'closing';
    this.triggerEvent('close');

    if (!this.options.overlay) {
      $body.removeClass(`mdui-drawer-body-${this.position}`);
    }

    this.$element
      .addClass('mdui-drawer-close')
      .removeClass('mdui-drawer-open')
      .transitionEnd(() => this.transitionEnd());

    if (this.overlay) {
      $.hideOverlay();
      this.overlay = false;
      $.unlockScreen();
    }
  }

  /**
   * 鍒囨崲鎶藉眽鏍忔墦寮€/鍏抽棴鐘舵€乗n   */
  public toggle(): void {
    this.isOpen() ? this.close() : this.open();
  }

  /**
   * 杩斿洖褰撳墠鎶藉眽鏍忕殑鐘舵€併€傚叡鍖呭惈鍥涚鐘舵€侊細`opening`銆乣opened`銆乣closing`銆乣closed`
   */
  public getState(): STATE {
    return this.state;
  }
}

mdui.Drawer = Drawer;
