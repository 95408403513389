import $ from 'mdui.jq/es/$';
import mdui from '../../mdui';
import { $document } from '../../utils/dom';
import { parseOptions } from '../../utils/parseOptions';
import './index';

const customAttr = 'mdui-fab';

$(() => {
  // mouseenter 涓嶅啋娉★紝鏃犳硶杩涜浜嬩欢濮旀墭锛岃繖閲岀敤 mouseover 浠ｆ浛銆俓n  // 涓嶇鏄 click 銆 mouseover 杩樻槸 touchstart 锛岄兘鍏堝垵濮嬪寲銆俓n
  $document.on(
    'touchstart mousedown mouseover',
    `[${customAttr}]`,
    function () {
      new mdui.Fab(
        this as HTMLElement,
        parseOptions(this as HTMLElement, customAttr),
      );
    },
  );
});
