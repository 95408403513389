import { isUndefined } from 'mdui.jq/es/utils';
import PlainObject from 'mdui.jq/es/interfaces/PlainObject';

type Func = () => any;

const container: PlainObject<Func[]> = {};

/**
 * 鏍规嵁闃熷垪鍚嶏紝鑾峰彇闃熷垪涓墍鏈夊嚱鏁癨n * @param name 闃熷垪鍚峔n */
function queue(name: string): Func[];

/**
 * 鍐欏叆闃熷垪
 * @param name 闃熷垪鍚峔n * @param func 鍑芥暟
 */
function queue(name: string, func: Func): void;

function queue(name: string, func?: Func): void | Func[] {
  if (isUndefined(container[name])) {
    container[name] = [];
  }

  if (isUndefined(func)) {
    return container[name];
  }

  container[name].push(func);
}

/**
 * 浠庨槦鍒椾腑绉婚櫎绗竴涓嚱鏁帮紝骞舵墽琛岃鍑芥暟
 * @param name 闃熷垪婊n */
function dequeue(name: string): void {
  if (isUndefined(container[name])) {
    return;
  }

  if (!container[name].length) {
    return;
  }

  const func = container[name].shift()!;

  func();
}

export { queue, dequeue };
