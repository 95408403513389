import Selector from 'mdui.jq/es/types/Selector';
import mdui from '../../mdui';
import { CollapseAbstract, OPTIONS } from '../collapse/collapseAbstract';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * 鍙墿灞曢潰鏉跨粍浠禱n     *
     * 璇烽€氳繃 `new mdui.Panel()` 璋冪敤
     */
    Panel: {
      /**
       * 瀹炰緥鍖 Panel 缁勪欢
       * @param selector CSS 閫夋嫨鍣ㄦ垨 DOM 鍏冪礌
       * @param options 閰嶇疆鍙傛暟
       */
      new (
        selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
        options?: OPTIONS,
      ): Panel;
    };
  }
}

class Panel extends CollapseAbstract {
  protected getNamespace(): string {
    return 'panel';
  }
}

mdui.Panel = Panel;
