import extend from 'mdui.jq/es/functions/extend';
import 'mdui.jq/es/methods/find';
import 'mdui.jq/es/methods/on';
import 'mdui.jq/es/methods/val';
import { isFunction, isUndefined } from 'mdui.jq/es/utils';
import mdui from '../../mdui';
import '../textfield';
import { Dialog } from './class';
import './dialog';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * 鎵撳紑涓€涓彁绀虹敤鎴疯緭鍏ョ殑瀵硅瘽妗嗭紝鍙互鍖呭惈鏍囬銆佹枃鏈鏍囩銆佹枃鏈銆佷竴涓‘璁ゆ寜閽拰涓€涓彇娑堟寜閽甛n     * @param label 鏂囨湰妗嗘诞鍔ㄦ爣绛剧殑鏂囨湰
     * @param title 鏍囬
     * @param onConfirm 鐐瑰嚮纭鎸夐挳鐨勫洖璋冦€傚惈涓や釜鍙傛暟锛屽垎鍒负鏂囨湰妗嗙殑鍊煎拰瀵硅瘽妗嗗疄渚媆n     * @param onCancel 鐐瑰嚮鍙栨秷鎸夐挳鐨勫洖璋冦€傚惈涓や釜鍙傛暟锛屽垎鍒负鏂囨湰妗嗙殑鍊煎拰瀵硅瘽妗嗗疄渚媆n     * @param options 閰嶇疆鍙傛暟
     */
    prompt(
      label: string,
      title: string,
      onConfirm?: (value: string, dialog: Dialog) => void,
      onCancel?: (value: string, dialog: Dialog) => void,
      options?: OPTIONS,
    ): Dialog;

    /**
     * 鎵撳紑涓€涓彁绀虹敤鎴疯緭鍏ョ殑瀵硅瘽妗嗭紝鍙互鍖呭惈鏂囨湰妗嗘爣绛俱€佹枃鏈銆佷竴涓‘璁ゆ寜閽拰涓€涓彇娑堟寜閽甛n     * @param label 鏂囨湰妗嗘诞鍔ㄦ爣绛剧殑鏂囨湰
     * @param onConfirm 鐐瑰嚮纭鎸夐挳鐨勫洖璋冦€傚惈涓や釜鍙傛暟锛屽垎鍒负鏂囨湰妗嗙殑鍊煎拰瀵硅瘽妗嗗疄渚媆n     * @param onCancel 鐐瑰嚮鍙栨秷鎸夐挳鐨勫洖璋冿紝鍚袱涓弬鏁帮紝鍒嗗埆涓烘枃鏈鐨勫€煎拰瀵硅瘽妗嗗疄渚媆n     * @param options 閰嶇疆鍙傛暟
     */
    prompt(
      label: string,
      onConfirm?: (value: string, dialog: Dialog) => void,
      onCancel?: (value: string, dialog: Dialog) => void,
      options?: OPTIONS,
    ): Dialog;
  }
}

type OPTIONS = {
  /**
   * 纭鎸夐挳鐨勬枃鏈琝n   */
  confirmText?: string;

  /**
   * 鍙栨秷鎸夐挳鐨勬枃鏈琝n   */
  cancelText?: string;

  /**
   * 鏄惁鐩戝惉 hashchange 浜嬩欢锛屼负 `true` 鏃跺彲浠ラ€氳繃 Android 鐨勮繑鍥為敭鎴栨祻瑙堝櫒鍚庨€€鎸夐挳鍏抽棴瀵硅瘽妗嗭紝榛樿涓 `true`
   */
  history?: boolean;

  /**
   * 鏄惁妯℃€佸寲瀵硅瘽妗嗐€備负 `false` 鏃剁偣鍑诲璇濇澶栭潰鐨勫尯鍩熸椂鍏抽棴瀵硅瘽妗嗭紝鍚﹀垯涓嶅叧闂紝榛樿涓 `false`
   */
  modal?: boolean;

  /**
   * 鏄惁鍦ㄦ寜涓 Esc 閿椂鏄惁鍏抽棴瀵硅瘽妗嗭紝榛樿涓 `true`
   */
  closeOnEsc?: boolean;

  /**
   * 鏄惁鍦ㄦ寜涓嬪彇娑堟寜閽椂鏄惁鍏抽棴瀵硅瘽妗哱n   */
  closeOnCancel?: boolean;

  /**
   * 鏄惁鍦ㄦ寜涓嬬‘璁ゆ寜閽椂鏄惁鍏抽棴瀵硅瘽妗哱n   */
  closeOnConfirm?: boolean;

  /**
   * 鏄惁鍦ㄦ寜涓 Enter 閿椂瑙﹀彂 `onConfirm` 鍥炶皟鍑芥暟锛岄粯璁や负 `false`
   */
  confirmOnEnter?: boolean;

  /**
   * 鏂囨湰妗嗙殑绫诲瀷銆俙text`: 鍗曡鏂囨湰妗嗭紱 `textarea`: 澶氳鏂囨湰妗哱n   */
  type?: 'text' | 'textarea';

  /**
   * 鏈€澶ц緭鍏ュ瓧绗︽暟閲忥紝涓 0 鏃惰〃绀轰笉闄愬埗
   */
  maxlength?: number;

  /**
   * 鏂囨湰妗嗙殑榛樿鍊糪n   */
  defaultValue?: string;
};

const DEFAULT_OPTIONS: OPTIONS = {
  confirmText: 'ok',
  cancelText: 'cancel',
  history: true,
  modal: false,
  closeOnEsc: true,
  closeOnCancel: true,
  closeOnConfirm: true,
  type: 'text',
  maxlength: 0,
  defaultValue: '',
  confirmOnEnter: false,
};

mdui.prompt = function (
  label: string,
  title?: any,
  onConfirm?: any,
  onCancel?: any,
  options?: any,
): Dialog {
  if (isFunction(title)) {
    options = onCancel;
    onCancel = onConfirm;
    onConfirm = title;
    title = '';
  }

  if (isUndefined(onConfirm)) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onConfirm = (): void => {};
  }

  if (isUndefined(onCancel)) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onCancel = (): void => {};
  }

  if (isUndefined(options)) {
    options = {};
  }

  options = extend({}, DEFAULT_OPTIONS, options);

  const content =
    '<div class="mdui-textfield">' +
    (label ? `<label class="mdui-textfield-label">${label}</label>` : '') +
    (options.type === 'text'
      ? `<input class="mdui-textfield-input" type="text" value="${
          options.defaultValue
        }" ${
          options.maxlength ? 'maxlength="' + options.maxlength + '"' : ''
        }/>`
      : '') +
    (options.type === 'textarea'
      ? `<textarea class="mdui-textfield-input" ${
          options.maxlength ? 'maxlength="' + options.maxlength + '"' : ''
        }>${options.defaultValue}</textarea>`
      : '') +
    '</div>';

  const onCancelClick = (dialog: Dialog): void => {
    const value = dialog.$element.find('.mdui-textfield-input').val();
    onCancel(value, dialog);
  };

  const onConfirmClick = (dialog: Dialog): void => {
    const value = dialog.$element.find('.mdui-textfield-input').val();
    onConfirm(value, dialog);
  };

  return mdui.dialog({
    title,
    content,
    buttons: [
      {
        text: options.cancelText,
        bold: false,
        close: options.closeOnCancel,
        onClick: onCancelClick,
      },
      {
        text: options.confirmText,
        bold: false,
        close: options.closeOnConfirm,
        onClick: onConfirmClick,
      },
    ],
    cssClass: 'mdui-dialog-prompt',
    history: options.history,
    modal: options.modal,
    closeOnEsc: options.closeOnEsc,
    onOpen: (dialog) => {
      // 鍒濆鍖栬緭鍏ユ
      const $input = dialog.$element.find('.mdui-textfield-input');
      mdui.updateTextFields($input);

      // 鑱氱劍鍒拌緭鍏ユ
      $input[0].focus();

      // 鎹曟崏鏂囨湰妗嗗洖杞﹂敭锛屽湪鍗曡鏂囨湰妗嗙殑鎯呭喌涓嬭Е鍙戝洖璋僜n      if (options.type !== 'textarea' && options.confirmOnEnter === true) {
        $input.on('keydown', (event) => {
          if ((event as KeyboardEvent).keyCode === 13) {
            const value = dialog.$element.find('.mdui-textfield-input').val();
            onConfirm(value, dialog);

            if (options.closeOnConfirm) {
              dialog.close();
            }

            return false;
          }

          return;
        });
      }

      // 濡傛灉鏄琛岃緭鍏ユ锛岀洃鍚緭鍏ユ鐨 input 浜嬩欢锛屾洿鏂板璇濇楂樺害
      if (options.type === 'textarea') {
        $input.on('input', () => dialog.handleUpdate());
      }

      // 鏈夊瓧绗︽暟闄愬埗鏃讹紝鍔犺浇瀹屾枃鏈鍚 DOM 浼氬彉鍖栵紝闇€瑕佹洿鏂板璇濇楂樺害
      if (options.maxlength) {
        dialog.handleUpdate();
      }
    },
  });
};
