import $ from 'mdui.jq/es/$';
import extend from 'mdui.jq/es/functions/extend';
import { JQ } from 'mdui.jq/es/JQ';
import 'mdui.jq/es/methods/addClass';
import 'mdui.jq/es/methods/css';
import 'mdui.jq/es/methods/each';
import 'mdui.jq/es/methods/find';
import 'mdui.jq/es/methods/first';
import 'mdui.jq/es/methods/hasClass';
import 'mdui.jq/es/methods/last';
import 'mdui.jq/es/methods/on';
import 'mdui.jq/es/methods/parents';
import 'mdui.jq/es/methods/removeClass';
import Selector from 'mdui.jq/es/types/Selector';
import mdui from '../../mdui';
import '../../jq_extends/methods/transitionEnd';
import { componentEvent } from '../../utils/componentEvent';
import { $document } from '../../utils/dom';
import { startEvent } from '../../utils/touchHandler';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * 娴姩鎿嶄綔鎸夐挳缁勪欢
     *
     * 璇烽€氳繃 `new mdui.Fab()` 璋冪敤
     */
    Fab: {
      /**
       * 瀹炰緥鍖 Fab 缁勪欢
       * @param selector CSS 閫夋嫨鍣ㄣ€佹垨 DOM 鍏冪礌銆佹垨 JQ 瀵硅薄
       * @param options 閰嶇疆鍙傛暟
       */
      new (
        selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
        options?: OPTIONS,
      ): Fab;
    };
  }
}

type OPTIONS = {
  /**
   * 瑙﹀彂鏂瑰紡銆俙hover`: 榧犳爣鎮诞瑙﹀彂锛沗click`: 鐐瑰嚮瑙﹀彂
   *
   * 榛樿涓 `hover`
   */
  trigger?: 'click' | 'hover';
};

type STATE = 'opening' | 'opened' | 'closing' | 'closed';
type EVENT = 'open' | 'opened' | 'close' | 'closed';

const DEFAULT_OPTIONS: OPTIONS = {
  trigger: 'hover',
};

class Fab {
  /**
   * Fab 鍏冪礌鐨 JQ 瀵硅薄
   */
  public $element: JQ;

  /**
   * 閰嶇疆鍙傛暟
   */
  public options: OPTIONS = extend({}, DEFAULT_OPTIONS);

  /**
   * 褰撳墠 fab 鐨勭姸鎬乗n   */
  private state: STATE = 'closed';

  /**
   * 鎸夐挳鍏冪礌
   */
  private $btn: JQ;

  /**
   * 鎷ㄥ彿鑿滃崟鍏冪礌
   */
  private $dial: JQ;

  /**
   * 鎷ㄥ彿鑿滃崟鍐呯殑鎸夐挳
   */
  private $dialBtns: JQ;

  public constructor(
    selector: Selector | HTMLElement | ArrayLike<HTMLElement>,
    options: OPTIONS = {},
  ) {
    this.$element = $(selector).first();

    extend(this.options, options);

    this.$btn = this.$element.find('.mdui-fab');
    this.$dial = this.$element.find('.mdui-fab-dial');
    this.$dialBtns = this.$dial.find('.mdui-fab');

    if (this.options.trigger === 'hover') {
      this.$btn.on('touchstart mouseenter', () => this.open());
      this.$element.on('mouseleave', () => this.close());
    }

    if (this.options.trigger === 'click') {
      this.$btn.on(startEvent, () => this.open());
    }

    // 瑙︽懜灞忓箷鍏朵粬鍦版柟鍏抽棴蹇€熸嫧鍙穃n    $document.on(startEvent, (event) => {
      if ($(event.target as HTMLElement).parents('.mdui-fab-wrapper').length) {
        return;
      }

      this.close();
    });
  }

  /**
   * 瑙﹀彂缁勪欢浜嬩欢
   * @param name
   */
  private triggerEvent(name: EVENT): void {
    componentEvent(name, 'fab', this.$element, this);
  }

  /**
   * 褰撳墠鏄惁涓烘墦寮€鐘舵€乗n   */
  private isOpen(): boolean {
    return this.state === 'opening' || this.state === 'opened';
  }

  /**
   * 鎵撳紑蹇€熸嫧鍙疯彍鍗昞n   */
  public open(): void {
    if (this.isOpen()) {
      return;
    }

    // 涓鸿彍鍗曚腑鐨勬寜閽坊鍔犱笉鍚岀殑 transition-delay
    this.$dialBtns.each((index, btn) => {
      const delay = `${15 * (this.$dialBtns.length - index)}ms`;

      btn.style.transitionDelay = delay;
      btn.style.webkitTransitionDelay = delay;
    });

    this.$dial.css('height', 'auto').addClass('mdui-fab-dial-show');

    // 濡傛灉鎸夐挳涓瓨鍦 .mdui-fab-opened 鐨勫浘鏍囷紝鍒欒繘琛屽浘鏍囧垏鎹n    if (this.$btn.find('.mdui-fab-opened').length) {
      this.$btn.addClass('mdui-fab-opened');
    }

    this.state = 'opening';
    this.triggerEvent('open');

    // 鎵撳紑椤哄簭涓轰粠涓嬪埌涓婇€愪釜鎵撳紑锛屾渶涓婇潰鐨勬墦寮€鍚庢墠琛ㄧず鍔ㄧ敾瀹屾垚
    this.$dialBtns.first().transitionEnd(() => {
      if (this.$btn.hasClass('mdui-fab-opened')) {
        this.state = 'opened';
        this.triggerEvent('opened');
      }
    });
  }

  /**
   * 鍏抽棴蹇€熸嫧鍙疯彍鍗昞n   */
  public close(): void {
    if (!this.isOpen()) {
      return;
    }

    // 涓鸿彍鍗曚腑鐨勬寜閽坊鍔犱笉鍚岀殑 transition-delay
    this.$dialBtns.each((index, btn) => {
      const delay = `${15 * index}ms`;

      btn.style.transitionDelay = delay;
      btn.style.webkitTransitionDelay = delay;
    });

    this.$dial.removeClass('mdui-fab-dial-show');
    this.$btn.removeClass('mdui-fab-opened');
    this.state = 'closing';
    this.triggerEvent('close');

    // 浠庝笂寰€涓嬩緷娆″叧闂紝鏈€鍚庝竴涓叧闂悗鎵嶈〃绀哄姩鐢诲畬鎴怽n    this.$dialBtns.last().transitionEnd(() => {
      if (this.$btn.hasClass('mdui-fab-opened')) {
        return;
      }

      this.state = 'closed';
      this.triggerEvent('closed');
      this.$dial.css('height', 0);
    });
  }

  /**
   * 鍒囨崲蹇€熸嫧鍙疯彍鍗曠殑鎵撳紑鐘舵€乗n   */
  public toggle(): void {
    this.isOpen() ? this.close() : this.open();
  }

  /**
   * 浠ュ姩鐢荤殑褰㈠紡鏄剧ず鏁翠釜娴姩鎿嶄綔鎸夐挳
   */
  public show(): void {
    this.$element.removeClass('mdui-fab-hide');
  }

  /**
   * 浠ュ姩鐢荤殑褰㈠紡闅愯棌鏁翠釜娴姩鎿嶄綔鎸夐挳
   */
  public hide(): void {
    this.$element.addClass('mdui-fab-hide');
  }

  /**
   * 杩斿洖褰撳墠蹇€熸嫧鍙疯彍鍗曠殑鎵撳紑鐘舵€併€傚叡鍖呭惈鍥涚鐘舵€侊細`opening`銆乣opened`銆乣closing`銆乣closed`
   */
  public getState(): STATE {
    return this.state;
  }
}

mdui.Fab = Fab;
