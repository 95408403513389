import $ from 'mdui.jq/es/$';
import 'mdui.jq/es/methods/each';
import mdui from '../mdui';
import '../jq_extends/methods/mutation';
import { isUndefined } from 'mdui.jq/es/utils';
import { TYPE_API_INIT, entries, mutation } from '../utils/mutation';

declare module '../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * 浼犲叆浜嗕袱涓弬鏁版椂锛屾敞鍐屽苟鎵ц鍒濆鍖栧嚱鏁癨n     *
     * 娌℃湁浼犲叆鍙傛暟鏃讹紝鎵ц鍒濆鍖朶n     * @param selector CSS 閫夋嫨鍣╘n     * @param apiInit 鍒濆鍖栧嚱鏁癨n     * @example
```js
mdui.mutation();
```
     * @example
```js
mdui.mutation();
```
     */
    mutation(selector?: string, apiInit?: TYPE_API_INIT): void;
  }
}

mdui.mutation = function (selector?: string, apiInit?: TYPE_API_INIT): void {
  if (isUndefined(selector) || isUndefined(apiInit)) {
    $(document).mutation();
    return;
  }

  entries[selector] = apiInit!;
  $(selector).each((i, element) => mutation(selector, apiInit, i, element));
};
