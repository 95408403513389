import $ from 'mdui.jq/es/$';
import extend from 'mdui.jq/es/functions/extend';
import 'mdui.jq/es/methods/addClass';
import 'mdui.jq/es/methods/appendTo';
import 'mdui.jq/es/methods/attr';
import 'mdui.jq/es/methods/each';
import 'mdui.jq/es/methods/find';
import 'mdui.jq/es/methods/is';
import 'mdui.jq/es/methods/on';
import 'mdui.jq/es/methods/outerHeight';
import 'mdui.jq/es/methods/parent';
import 'mdui.jq/es/methods/parents';
import 'mdui.jq/es/methods/remove';
import 'mdui.jq/es/methods/removeClass';
import 'mdui.jq/es/methods/text';
import 'mdui.jq/es/methods/trigger';
import 'mdui.jq/es/methods/val';
import Selector from 'mdui.jq/es/types/Selector';
import { isUndefined } from 'mdui.jq/es/utils';
import mdui from '../../mdui';
import '../../global/mutation';
import { $document } from '../../utils/dom';

declare module '../../interfaces/MduiStatic' {
  interface MduiStatic {
    /**
     * 鍔ㄦ€佷慨鏀逛簡鏂囨湰妗嗗悗锛岄渶瑕佽皟鐢ㄨ鏂规硶閲嶆柊鍒濆鍖栨枃鏈銆俓n     *
     * 鑻ヤ紶鍏ヤ簡鍙傛暟锛屽垯鍙垵濮嬪寲璇ュ弬鏁板搴旂殑鏂囨湰妗嗐€傝嫢娌℃湁浼犲叆鍙傛暟锛屽垯閲嶆柊鍒濆鍖栨墍鏈夋枃鏈銆俓n     * @param selector CSS 閫夋嫨鍣ㄣ€佹垨 DOM 鍏冪礌銆佹垨 DOM 鍏冪礌缁勬垚鐨勬暟缁勩€佹垨 JQ 瀵硅薄
     */
    updateTextFields(
      selector?: Selector | HTMLElement | ArrayLike<HTMLElement>,
    ): void;
  }
}

type INPUT_EVENT_DATA = {
  reInit?: boolean;
  domLoadedEvent?: boolean;
};

const defaultData: INPUT_EVENT_DATA = {
  reInit: false,
  domLoadedEvent: false,
};

/**
 * 杈撳叆妗嗕簨浠禱n * @param event
 * @param data
 */
function inputEvent(event: Event, data: INPUT_EVENT_DATA = {}): void {
  data = extend({}, defaultData, data);

  const input = event.target as HTMLInputElement;
  const $input = $(input);
  const eventType = event.type;
  const value = $input.val() as string;

  // 鏂囨湰妗嗙被鍨媆n  const inputType = $input.attr('type') || '';
  if (
    ['checkbox', 'button', 'submit', 'range', 'radio', 'image'].indexOf(
      inputType,
    ) > -1
  ) {
    return;
  }

  const $textfield = $input.parent('.mdui-textfield');

  // 杈撳叆妗嗘槸鍚﹁仛鐒n  if (eventType === 'focus') {
    $textfield.addClass('mdui-textfield-focus');
  }

  if (eventType === 'blur') {
    $textfield.removeClass('mdui-textfield-focus');
  }

  // 杈撳叆妗嗘槸鍚︿负绌篭n  if (eventType === 'blur' || eventType === 'input') {
    value
      ? $textfield.addClass('mdui-textfield-not-empty')
      : $textfield.removeClass('mdui-textfield-not-empty');
  }

  // 杈撳叆妗嗘槸鍚︾鐢╘n  input.disabled
    ? $textfield.addClass('mdui-textfield-disabled')
    : $textfield.removeClass('mdui-textfield-disabled');

  // 琛ㄥ崟楠岃瘉
  if (
    (eventType === 'input' || eventType === 'blur') &&
    !data.domLoadedEvent &&
    input.validity
  ) {
    input.validity.valid
      ? $textfield.removeClass('mdui-textfield-invalid-html5')
      : $textfield.addClass('mdui-textfield-invalid-html5');
  }

  // textarea 楂樺害鑷姩璋冩暣
  if ($input.is('textarea')) {
    // IE bug锛歵extarea 鐨勫€间粎涓哄涓崲琛岋紝涓嶅惈鍏朵粬鍐呭鏃讹紝textarea 鐨勯珮搴︿笉鍑嗙‘
    //         姝ゆ椂锛屽湪璁＄畻楂樺害鍓嶏紝鍦ㄥ€肩殑寮€澶村姞鍏ヤ竴涓┖鏍硷紝璁＄畻瀹屽悗锛岀Щ闄ょ┖鏍糪n    const inputValue = value;
    let hasExtraSpace = false;

    if (inputValue.replace(/[\r\n]/g, '') === '') {
      $input.val(' ' + inputValue);
      hasExtraSpace = true;
    }

    // 璁剧疆 textarea 楂樺害
    $input.outerHeight('');
    const height = $input.outerHeight();
    const scrollHeight = input.scrollHeight;

    if (scrollHeight > height) {
      $input.outerHeight(scrollHeight);
    }

    // 璁＄畻瀹岋紝杩樺師 textarea 鐨勫€糪n    if (hasExtraSpace) {
      $input.val(inputValue);
    }
  }

  // 瀹炴椂瀛楁暟缁熻
  if (data.reInit) {
    $textfield.find('.mdui-textfield-counter').remove();
  }

  const maxLength = $input.attr('maxlength');
  if (maxLength) {
    if (data.reInit || data.domLoadedEvent) {
      $(
        '<div class="mdui-textfield-counter">' +
          `<span class="mdui-textfield-counter-inputed"></span> / ${maxLength}` +
          '</div>',
      ).appendTo($textfield);
    }

    $textfield
      .find('.mdui-textfield-counter-inputed')
      .text(value.length.toString());
  }

  // 鍚 甯姪鏂囨湰銆侀敊璇彁绀恒€佸瓧鏁扮粺璁 鏃讹紝澧炲姞鏂囨湰妗嗗簳閮ㄥ唴杈硅窛
  if (
    $textfield.find('.mdui-textfield-helper').length ||
    $textfield.find('.mdui-textfield-error').length ||
    maxLength
  ) {
    $textfield.addClass('mdui-textfield-has-bottom');
  }
}

$(() => {
  // 缁戝畾浜嬩欢
  $document.on(
    'input focus blur',
    '.mdui-textfield-input',
    { useCapture: true },
    inputEvent,
  );

  // 鍙睍寮€鏂囨湰妗嗗睍寮€
  $document.on(
    'click',
    '.mdui-textfield-expandable .mdui-textfield-icon',
    function () {
      $(this as HTMLElement)
        .parents('.mdui-textfield')
        .addClass('mdui-textfield-expanded')
        .find('.mdui-textfield-input')[0]
        .focus();
    },
  );

  // 鍙睍寮€鏂囨湰妗嗗叧闂璡n  $document.on(
    'click',
    '.mdui-textfield-expanded .mdui-textfield-close',
    function () {
      $(this)
        .parents('.mdui-textfield')
        .removeClass('mdui-textfield-expanded')
        .find('.mdui-textfield-input')
        .val('');
    },
  );

  /**
   * 鍒濆鍖栨枃鏈
   */
  mdui.mutation('.mdui-textfield', function () {
    $(this).find('.mdui-textfield-input').trigger('input', {
      domLoadedEvent: true,
    });
  });
});

mdui.updateTextFields = function (
  selector?: Selector | HTMLElement | ArrayLike<HTMLElement>,
): void {
  const $elements = isUndefined(selector) ? $('.mdui-textfield') : $(selector);

  $elements.each((_, element) => {
    $(element).find('.mdui-textfield-input').trigger('input', {
      reInit: true,
    });
  });
};
