/**
 * touch 浜嬩欢鍚庣殑 500ms 鍐呯鐢 mousedown 浜嬩欢
 *
 * 涓嶆敮鎸佽Е鎺х殑灞忓箷涓婁簨浠堕『搴忎负 mousedown -> mouseup -> click
 * 鏀寔瑙︽帶鐨勫睆骞曚笂浜嬩欢椤哄簭涓 touchstart -> touchend -> mousedown -> mouseup -> click
 *
 * 鍦ㄦ瘡涓€涓簨浠朵腑閮戒娇鐢 TouchHandler.isAllow(event) 鍒ゆ柇浜嬩欢鏄惁鍙墽琛孿n * 鍦 touchstart 鍜 touchmove銆乼ouchend銆乼ouchcancel
 *
 * (function () {
 *   $document
 *     .on(start, function (e) {
 *       if (!isAllow(e)) {
 *         return;
 *       }
 *       register(e);
 *       console.log(e.type);
 *     })
 *     .on(move, function (e) {
 *       if (!isAllow(e)) {
 *         return;
 *       }
 *       console.log(e.type);
 *     })
 *     .on(end, function (e) {
 *       if (!isAllow(e)) {
 *         return;
 *       }
 *       console.log(e.type);
 *     })
 *     .on(unlock, register);
 * })();
 */

const startEvent = 'touchstart mousedown';
const moveEvent = 'touchmove mousemove';
const endEvent = 'touchend mouseup';
const cancelEvent = 'touchcancel mouseleave';
const unlockEvent = 'touchend touchmove touchcancel';

let touches = 0;

/**
 * 璇ヤ簨浠舵槸鍚﹁鍏佽锛屽湪鎵ц浜嬩欢鍓嶈皟鐢ㄨ鏂规硶鍒ゆ柇浜嬩欢鏄惁鍙互鎵ц
 * 鑻ュ凡瑙﹀彂 touch 浜嬩欢锛屽垯闃绘涔嬪悗鐨勯紶鏍囦簨浠禱n * @param event
 */
function isAllow(event: Event): boolean {
  return !(
    touches &&
    [
      'mousedown',
      'mouseup',
      'mousemove',
      'click',
      'mouseover',
      'mouseout',
      'mouseenter',
      'mouseleave',
    ].indexOf(event.type) > -1
  );
}

/**
 * 鍦 touchstart 鍜 touchmove銆乼ouchend銆乼ouchcancel 浜嬩欢涓皟鐢ㄨ鏂规硶娉ㄥ唽浜嬩欢
 * @param event
 */
function register(event: Event): void {
  if (event.type === 'touchstart') {
    // 瑙﹀彂浜 touch 浜嬩欢
    touches += 1;
  } else if (
    ['touchmove', 'touchend', 'touchcancel'].indexOf(event.type) > -1
  ) {
    // touch 浜嬩欢缁撴潫 500ms 鍚庤В闄ゅ榧犳爣浜嬩欢鐨勯樆姝n    setTimeout(function () {
      if (touches) {
        touches -= 1;
      }
    }, 500);
  }
}

export {
  startEvent,
  moveEvent,
  endEvent,
  cancelEvent,
  unlockEvent,
  isAllow,
  register,
};
